import { collection, getDocs, query, where } from "firebase/firestore";
import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Event from "./Event";
import Login from "./Login";
import Posts from "./Posts";
import Reset from "./Reset";
import Services from "./Services";
import { auth, db, trackException } from "./services/firebase";

import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDispatch, useSelector } from "react-redux";
import "./App.css";
import AppSettings from "./AppSettings";
import Attractions from "./Attractions";
import Coach from "./Coach";
import CoachMember from "./CoachMember";
import DrivingRoads from "./DrivingRoads";
import Group from "./Group";
import KeyCafeKeys from "./KeyCafeKeys";
import Notifications from "./Notifications";
import PolicyPDF from "./PolicyPDF";
import Rental from "./Rental";
import Restaurants from "./Restaurants";
import Room from "./Room";
import RoomAddEdit from "./RoomAddEdit";
import ServiceProvider from "./ServiceProvider";
import UserDetails from "./UserDetails";
import Users from "./Users";
import VehicleServiceType from "./VehicleServiceTypes";
import Vehicles from "./Vehicles";
import { getUserData, setUserData } from "./slices";
import LoadingScreen from "./components/LoadingScreen";
import VehiclesRequest from "./VehiclesRequest";
import ProductOrderHistory from "./ProductOrderHistory"
import UserWalletHistory from "./UserWalletHistory";
import POSOrder from "./POSOrder";
import UserChargeDetails from "./UserChargeDetails";
import SpaceClosures from "./SpaceClosures";
import SixMonthBillingCalculation from "./SixMonthBillingCalculation"
import OperatingHours from "./OperatingHours";
import ReportHistory from "./ReportHistory"
import ReportByPayIntent from "./ReportByPayIntent"
import ReportByCloverOrder from "./ReportByCloverOrder"

function App() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(); // <-- initially undefined
  const [user, loading, error] = useAuthState(auth);
  const [accessPermission, setAccessPermission] = useState([]);
  const userInfo = useSelector(getUserData);

  useEffect(() => {
    if (loading) return;
    if (!user) {
      setIsLoggedIn(false)
      setIsLoading(false)
    } else {
      getUserDetails()
    }
  }, [user, loading]);

  async function getUserDetails() {
    localStorage.clear();
    try {
      const userquery = query(collection(db, "Users"), where("id", "==", user?.uid));
      const userdoc = await getDocs(userquery);
      if (userdoc.docs.length > 0) {
        const userData = userdoc.docs[0].data();
        if (userData.accessPermission) {
          setAccessPermission(userData.accessPermission)
          dispatch(setUserData(userData));
          setIsLoggedIn(true)
        } else {
          setIsLoggedIn(false)
        }
      } else {
        setIsLoggedIn(false)
      }
      setIsLoading(false)
    } catch (err) {
      console.log(err);
      setIsLoggedIn(false)
      setIsLoading(false)
      trackException(JSON.stringify(err))
      alert("An error occured while fetching user data");
    }
  }

  // Define access control function
  const hasAccess = (permission) => {
    // If user is admin, grant access to all routes
    if (accessPermission.includes("Admin")) {
      return true;
    }
    // Otherwise, check if user has specific permission
    return accessPermission.includes(permission);
  };


  if (isLoading) { // <-- check if undefined
    return <LoadingScreen />; // or loading indicator, etc...
  }

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/policy" element={<PolicyPDF />} />
          <Route exact path="/reset" element={<Reset />} />

          {/* COMMUNITY */}
          {hasAccess("Community") && <Route exact path="/event" element={<Event />} />}
          {hasAccess("Community") && <Route exact path="/post" element={<Posts />} />}
          {hasAccess("Community") && <Route exact path="/operatingHours" element={<OperatingHours />} />}
          {hasAccess("Community") && <Route exact path="/spaceClosures" element={<SpaceClosures />} />}

          {/* RESERVATIONS */}
          {hasAccess("Reservations") && <Route exact path="/vehicleservicetypes" element={<VehicleServiceType />} />}
          {hasAccess("Reservations") && <Route exact path="/serviceprovider" element={<ServiceProvider />} />}
          {hasAccess("Reservations") && <Route exact path="/services" element={<Services />} />}
          {hasAccess("Reservations") && <Route exact path="/room" element={<Room />} />}
          {hasAccess("Reservations") && <Route exact path="/coach" element={<Coach />} />}
          {hasAccess("Reservations") && <Route exact path="/rental" element={<Rental />} />}

          {/* LOCATIONS */}
          {hasAccess("Locations") && <Route exact path="/attractions" element={<Attractions />} />}
          {hasAccess("Locations") && <Route exact path="/restaurants" element={<Restaurants />} />}
          {hasAccess("Locations") && <Route exact path="/drivingroads" element={<DrivingRoads />} />}

          {/* PEOPLE */}
          {hasAccess("People") && <Route exact path="/user" element={<Users />} />}
          {hasAccess("People") && <Route exact path="/CoachMember" element={<CoachMember />} />}
          {hasAccess("People") && <Route exact path="/addrooms" element={<RoomAddEdit />} />}
          {hasAccess("People") && <Route exact path="/group" element={<Group />} />}
          {hasAccess("People") && <Route exact path="/vehicle" element={<Vehicles />} />}
          {hasAccess("People") && <Route exact path="/VehiclesRequest" element={<VehiclesRequest />} />}
          {hasAccess("People") && <Route exact path="/keyCafeKeys" element={<KeyCafeKeys />} />}
          {hasAccess("People") && <Route exact path="/userDetails" element={<UserDetails />} />}
          {hasAccess("People") && <Route exact path="/userChargeDetails" element={<UserChargeDetails />} />}
          {hasAccess("People") && <Route exact path="/notifications" element={<Notifications />} />}
          {hasAccess("People") && <Route exact path="/UserWalletHistory" element={<UserWalletHistory />} />}

          {/* POS */}
          {hasAccess("POS") && <Route exact path="/POSOrder" element={<POSOrder />} />}
          {hasAccess("POS") && <Route exact path="/ProductOrderHistory" element={<ProductOrderHistory />} />}


          <Route exact path="/ReportHistory" element={isLoggedIn ? <ReportHistory /> : <Navigate to="/" />} />
          <Route exact path="/ReportByPayIntent" element={isLoggedIn ? <ReportByPayIntent /> : <Navigate to="/" />} />
          <Route exact path="/ReportByCloverOrder" element={isLoggedIn ? <ReportByCloverOrder /> : <Navigate to="/" />} />


          <Route exact path="/AppSettings" element={isLoggedIn ? <AppSettings /> : <Navigate to="/" />} />
          <Route exact path="/SixMonthBillingCalculation" element={isLoggedIn ? <SixMonthBillingCalculation /> : <Navigate to="/" />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
