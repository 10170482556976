import {
    Button, Label, Table, TableBody,
    TableCell,
    TableCellActions,
    TableCellLayout,
    TableHeader,
    TableHeaderCell,
    TableRow,
    makeStyles, shorthands,
    TabList, Tab,
    Spinner
} from "@fluentui/react-components";
import { DeleteFilled, DeleteRegular, EditFilled, EditRegular, bundleIcon } from "@fluentui/react-icons";
import { collection, getDocs, query, where } from "firebase/firestore";
import debounce from 'lodash/debounce';
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import CustomSearchInput from "./components/CustomSearchInput";
import DeleteEventDialog from "./components/DeleteEventDialog";
import OperatingHoursForm from "./components/Forms/OperatingHoursForm";
import Layout from "./components/Layout";
import { auth, db, trackException } from "./services/firebase";
import roomsDataService from "./services/roomsDataService";
import { PlusSVG } from "./static/svgs";

const EditIcon = bundleIcon(EditFilled, EditRegular);
const DeleteIcon = bundleIcon(DeleteFilled, DeleteRegular);

const useStyles = makeStyles({
    root: {
        ...shorthands.overflow("hidden"),
        display: "flex",
        height: "480px",
        backgroundColor: "#fff"
    },

    content: {
        ...shorthands.flex(1),
        ...shorthands.padding("16px"),
        display: "block",
        ...shorthands.gridArea("main")
    },

    breakFlex: {
        flexBasis: "100%",
        textAlign: "left"
    }
});

const columns = [
    { columnKey: "dayoftheWeek", label: "Day of the Week" },
    { columnKey: "openingTime", label: "Opening Time" },
    { columnKey: "closingTime", label: "Closing Time" },
];

const daysOfWeek = [
    { id: "monday", name: "Monday" },
    { id: "tuesday", name: "Tuesday" },
    { id: "wednesday", name: "Wednesday" },
    { id: "thursday", name: "Thursday" },
    { id: "friday", name: "Friday" },
    { id: "saturday", name: "Saturday" },
    { id: "sunday", name: "Sunday" },
];
const hoursType = [
    { id: "restaurantBar", name: "Restaurant & Bar Hours" },
    { id: "clubHours", name: "Club Hours" },
];
const getDayName = (dayId) => {
    const day = daysOfWeek.find(d => d.id === dayId);
    return day ? day.name : dayId; // Return the name if found, otherwise return the original ID
};
function OperatingHours() {
    const styles = useStyles();
    const [user, loading, error] = useAuthState(auth);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [selectedRoom, setSelectedRoom] = useState();
    const [searchTerm, setSearchTerm] = useState('');
    const [currentuserId, setCurrentuserId] = React.useState('');
    const [operatingHours, setOperatingHours] = useState([]);
    const [filteredOperatingHours, setFilteredOperatingHours] = useState([]);
    const [selectedTabValue, setSelectedTabValue] = React.useState("restaurantBar");
    const [isLoading, setIsLoading] = useState(false);

    const [open, setOpen] = React.useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
    const navigate = useNavigate();
    const openForm = (selectedItem) => {
        setIsFormOpen(true);
        setSelectedRoom(selectedItem);
    }

    const fetchUserNameAndRooms = async () => {
        try {
            if (!!user) {
                setIsLoading(true)
                const userquery = query(collection(db, "Users"), where("email", "==", user?.email));
                const userdoc = await getDocs(userquery);
                const currentuser = userdoc.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
                setCurrentuserId(currentuser[0].id);

                if (userdoc.docs.length > 0) {
                    const closureHoursQuery = await roomsDataService.getOperatingHours();
                    let closureHoursArr = closureHoursQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
                    // Filter for only items with type 'SpaceClosure'
                    let spaceClosures = closureHoursArr.filter(item => item.type === "OperatingHours");
                    setOperatingHours(spaceClosures);
                    const filteredHours = spaceClosures.filter(hour => hour.hoursType == selectedTabValue);
                    setFilteredOperatingHours(filteredHours);
                    setIsLoading(false)
                } else {
                    console.log('no access');
                    setIsLoading(false)
                }
            }
        } catch (err) {
            setIsLoading(false)
            trackException(JSON.stringify(err))
            console.error(err);
            alert("An error occured while fetching user data");
        }
    };


    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/");
        fetchUserNameAndRooms();
    }, [user, loading]);

    useEffect(() => {
        if (selectedTabValue) {
            const filteredHours = operatingHours.filter(hour => hour.hoursType == selectedTabValue);
            setFilteredOperatingHours(filteredHours);
        }
    }, [selectedTabValue])

    const openDeleteForm = (selectedItem) => {
        setIsDeleteDialogOpen(true);
        setSelectedRoom(selectedItem);
        // fetchUserNameAndEvents();
    }

    const DeleteRoom = async () => {
        roomsDataService.deleteOperatingHours(selectedRoom.id);
        setIsDeleteDialogOpen(false);
        fetchUserNameAndRooms();
    };

    // Debounce the handleSearch function
    const debouncedSearch = debounce(search => {
        const lowerSearch = search.toLowerCase();
        const filtered = operatingHours.filter(event => {
            return (
                event.name.toLowerCase().includes(lowerSearch)
            )
        });
        setFilteredOperatingHours(filtered);
    }, 300); // Adjust the debounce delay as needed

    const handleSearch = event => {
        const value = event.target.value;
        setSearchTerm(value);
        debouncedSearch(value);
    };
    const handleClear = () => {
        setSearchTerm('');
        debouncedSearch('');
    };

    const onTabSelect = (event, data) => {
        setSelectedTabValue(data.value);
        const filteredHours = operatingHours.filter(hour => hour.hoursType === data.value);
        setFilteredOperatingHours(filteredHours);
    };

    return (
        <Layout>
            <div className={styles.content}>
                <div className={"d-flex justify-space-between"}>
                    <Button icon={<PlusSVG />} className={"primary-btn"} shape="circular" appearance="primary" onClick={() => openForm(undefined)}>{'NEW Operating Hours'.toUpperCase()}</Button>
                    {/* <CustomSearchInput
                        value={searchTerm}
                        onChange={handleSearch}
                        onClick={handleClear}
                    /> */}
                </div>
                {
                    operatingHours && operatingHours.length > 0 ?
                        <div className={'d-flex'} style={{ marginTop: 20 }}>
                            <TabList selectedValue={selectedTabValue} onTabSelect={onTabSelect}>
                                {hoursType.map(tab => (
                                    <Tab key={tab.id} value={tab.id}>
                                        {tab.name}
                                    </Tab>
                                ))}
                            </TabList>
                        </div> :
                        <div />
                }

                <div style={{ marginTop: '-1px', marginLeft: '12px', height: 1, backgroundColor: '#E6E6E6', width: '100%' }} />
                <div className={"table-layout"}>
                    <Table aria-label="Table with cell actions">
                        <TableHeader className={"list-row"}>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableHeaderCell key={column.columnKey}>
                                        <span className={"listHeaderText"}>{column.label}</span>
                                    </TableHeaderCell>
                                ))}
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {filteredOperatingHours && filteredOperatingHours.length > 0 && filteredOperatingHours.map((item) =>
                                <TableRow key={item.id}>
                                    {/* Day of the Week */}
                                    <TableCell className={"table-row-fonts__desc"}>
                                        <TableCellLayout>
                                            <Label>
                                                {item.dateRangeOn ? `${getDayName(item.startDay)} - ${getDayName(item.endDay)}` : getDayName(item.dayOfWeek)}
                                            </Label>
                                        </TableCellLayout>
                                    </TableCell>

                                    {/* Opening Time */}
                                    <TableCell className={"table-row-fonts__desc"}>
                                        <TableCellLayout>
                                            <Label>
                                                {moment(item.startTime, "HH:mm").format("hh:mm A")}
                                            </Label>
                                        </TableCellLayout>
                                    </TableCell>

                                    {/* Closing Time */}
                                    <TableCell className={"table-row-fonts__desc"}>
                                        <TableCellLayout>
                                            <Label>
                                                {moment(item.endTime, "HH:mm").format("hh:mm A")}
                                            </Label>
                                        </TableCellLayout>
                                        <TableCellActions>
                                            {/* Edit and Delete Buttons */}
                                            <Button
                                                icon={<EditIcon />}
                                                appearance="subtle"
                                                aria-label="Edit"
                                                onClick={() => openForm(item)}
                                            />
                                            <Button
                                                icon={<DeleteIcon />}
                                                appearance="subtle"
                                                aria-label="Delete"
                                                onClick={() => openDeleteForm(item)}
                                            />
                                        </TableCellActions>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>

                    </Table>
                </div>
                <div style={{ marginTop: '10px', textAlign: 'center' }}>
                    {isLoading && <Spinner />} {/* Render loader if loading state is true */}
                </div>
            </div>
            <OperatingHoursForm isOpen={isFormOpen} setIsOpen={(val) => setIsFormOpen(val)}
                selectedOperatingHour={selectedRoom}
                updateGrid={() => { fetchUserNameAndRooms() }} createdBy={currentuserId}></OperatingHoursForm>

            <DeleteEventDialog isDeleteDialogOpen={isDeleteDialogOpen}
                setIsDeleteDialogOpen={setIsDeleteDialogOpen} DeleteEvent={DeleteRoom} text={'Operating Hours'} />
        </Layout>
    )
}

export default OperatingHours;