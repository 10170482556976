import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, db, logInWithEmailAndPassword, trackException } from "./services/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import {
    signInWithEmailAndPassword
} from "firebase/auth";
import "./Login.css";
import { collection, getDoc, getDocs, query, where } from "firebase/firestore";
import { EyeViewDisableSVG, EyeViewSVG, LoginSvgs } from "./static/svgs";
import { Button, Input, Spinner } from "@fluentui/react-components";
import { setUserData } from "./slices";
import { useDispatch } from "react-redux";
function Login() {
    const dispatch = useDispatch();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loginFlagState, setLoginFlagState] = useState({
        isVisible: true,
        isLoading: false
    });
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();
    useEffect(() => {
        if (loading) {
            console.log("loading->", loading)
            // maybe trigger a loading screen
            return;
        }
        if (user) {
            console.log("user->", user)
            checkIsUserAdmin(user);
            //navigate("/event");
        }
    }, [user, loading]);


    async function checkIsUserAdmin(user) {
        try {
            const userquery = query(collection(db, "Users"), where("id", "==", user?.uid));
            const userdoc = await getDocs(userquery);
            console.log(userdoc.docs)
            if (userdoc.docs.length > 0) {
                const userData = userdoc.docs[0].data();
                if (userdoc.docs.length > 0) {
                    const userData = userdoc.docs[0].data();
                    if (userData.accessPermission && userData.accessPermission.length > 0) {
                        dispatch(setUserData(userData));
                        if (userData.accessPermission.includes("Admin")) {
                            navigate("/event");
                        } else if (userData.accessPermission.includes("Community")) {
                            navigate("/event");
                        } else if (userData.accessPermission.includes("Reservations")) {
                            navigate("/vehicleservicetypes");
                        } else if (userData.accessPermission.includes("People")) {
                            navigate("/user");
                        } else if (userData.accessPermission.includes("POS")) {
                            navigate("/POSOrder");
                        } else {
                            alert("You don't have permission to access.");
                        }
                    }
                } else {
                    alert("You don't have permission to access.");
                }
            } else {
            }
        } catch (err) {
            trackException(JSON.stringify(err))
            alert("An error occured while fetching user data");
        }
    }

    return (
        <div className="login">
            <div className={"d-flex margin-padding-none"} >
                <img src={'/images/loginLogo.png'} /> <div className={'login_logo__label '}>Warehouse Admin Panel</div>
            </div>
            <div className={"login__card"}>


                <div className="login__container">
                    <div className={"login_row__container"}>
                        <LoginSvgs />
                        <label className={"login_row_login__label vertical-center"}>Login</label>
                    </div>


                    <label className={"login_form__label login-fields__container"}>Email</label>
                    <Input
                        type="text"
                        className="login_field"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter Email"
                    />
                    <label className={"login_form__label login-password-fields"}>Password</label>
                    <Input
                        type={loginFlagState.isVisible ? "password" : "text"}
                        className="login_field"
                        contentAfter={<span className={"pointer"} onClick={() => {
                            setLoginFlagState((prevState) => ({ ...prevState, isVisible: !prevState.isVisible }))
                        }} > {loginFlagState?.isVisible ? <EyeViewSVG aria-label="Enter by voice" /> : <EyeViewDisableSVG />}</span>}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Enter Password"
                    />
                    <div>
                        <Link to="/reset" className={"forgot_password_label"}>Forgot Password</Link>

                    </div>
                    <Button
                        className="login__btn "

                        onClick={() => logInWithEmailAndPassword(email, password)}
                    >
                        LOGIN
                        {/*<Spinner label="Loading..." />*/}
                    </Button>

                </div>
            </div>

        </div>
    );
}

export default Login;