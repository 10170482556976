import {
    Button,
    Dropdown,
    Label,
    Option,
    Switch,
    makeStyles, shorthands, Spinner
} from "@fluentui/react-components";
import {
    DrawerBody,
    DrawerHeader,
    DrawerHeaderTitle,
    DrawerOverlay,
} from "@fluentui/react-components/unstable";
import { DeleteFilled, DeleteRegular, Dismiss24Regular, bundleIcon } from "@fluentui/react-icons";
import React, { useEffect, useState } from "react";
// import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { Controller, useForm } from "react-hook-form";
import eventDataService from "../../services/eventDataService";
import { BOOKINGSTATUS } from "../../Helper";

const DeleteIcon = bundleIcon(DeleteFilled, DeleteRegular);

export const InitialRSVPState = {
    "id": "",
    "createdDate": new Date().toISOString(),
    "userId": "",
    "isAttending": false,
    "noOfGuest": 0,
};

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        // Use 2px gap below the label (per the design system)
        ...shorthands.gap("2px")
    },
    control: {
        maxWidth: "300px",
    },
});

function EventRSVPForm(props) {
    const styles = useStyles();
    const { handleSubmit, control, reset, setValue, formState: { isDirty, dirtyFields } } = useForm({
        defaultValues: props.selectedRsvpMember && typeof (props.selectedRsvpMember) == "object" ? props.selectedRsvpMember : InitialRSVPState
    });
    const [selectedMemberPlaceholder, setSeletedMemberPlaceholder] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = async formdata => {
        var data = formdata;
        console.log("data->", data)
        if (data.userId) {
            checkAndUpdateRSVP(data)
        }
    }

    async function checkAndUpdateRSVP(data) {
        const isoDateString = new Date().toISOString();
        let fName = ''
        let lName = ''
        let selectedMember = props.users.filter(obj => obj.id == data.userId);
        if (selectedMember[0] && selectedMember[0].firstName) {
            fName = `${selectedMember[0].firstName}`
            lName = `${selectedMember[0].lastName}`
        }
        let eventObj = props.selectedEvent;
        const updatedRsvp = {
            userId: data.userId,
            isAttending: data.isAttending,
            firstName: fName ?? "",
            lastName: lName ?? "",
            createdDate: isoDateString,
            noOfGuest: data.isAttending ? parseInt(data.noOfGuest) : 0
        };
        let isAttending = data.isAttending
        //-1 remove member it self from guest count. Count is + on display time
        const eventDocId = props.selectedEvent.id
        // Update or add the rsvp object in the event document
        setIsLoading(true)
        try {
            let docSnapshot = await eventDataService.getEvent(eventDocId)
            const rsvpsArray = docSnapshot.get('rsvps') || [];
            console.log(rsvpsArray)
            let attendingArr = rsvpsArray?.filter(rsvp => rsvp.isAttending == true);
            if (attendingArr.length >= eventObj.maximumAttendees && isAttending) {
                //Maximum capacity for this event has been reached.
                if (isAttending) {
                    setIsLoading(false)
                    return
                } else {
                    setIsLoading(false)
                }
            } else {
                const existingIndex = rsvpsArray.findIndex((rsvp) => rsvp.userId === data.userId);
                if (existingIndex > -1) {
                    // If userId exists
                    rsvpsArray[existingIndex] = updatedRsvp;
                } else {
                    //TODO: If user alredy in waitlist then need to remove from rsvpWaitlist 
                    // If userId doesn't exist, add the new rsvp object
                    rsvpsArray.push(updatedRsvp);
                }
                //rsvpUids use for get the list of events that user only rsvp
                let adata = rsvpsArray;
                adata = adata.filter(function (item) { return item.isAttending == true; }).map(function ({ userId }) { return userId; });
                // Update the rsvps array in the event document
                try {
                    await eventDataService.updateEventFields(eventDocId, rsvpsArray, adata);
                    console.log('Event fields updated successfully');
                    if (isAttending) {
                        bookEvent(BOOKINGSTATUS.Attending, data.userId, props.selectedEvent, selectedMember[0])
                    } else {
                        bookEvent(BOOKINGSTATUS.Cancel, data.userId, props.selectedEvent, selectedMember[0])
                    }
                    setIsLoading(false)
                    props.updateGrid();
                    props.setIsOpen(false);
                } catch (error) {
                    setIsLoading(false)
                    alert(error)
                    console.log('Error updating event fields: ', error);
                }
            }
        } catch (error) {
            console.log("error->", error)
            setIsLoading(false)
            props.setIsOpen(false);
        }
    }

    async function bookEvent(status, createdBy, eventObj, userDetails) {
        const isoDateString = new Date().toISOString();
        const bookObjId = eventObj.id;
        try {
            // Check for an existing booking
            const querySnapshot = await eventDataService.getBookingByUserAndEvent(bookObjId, createdBy);
            if (querySnapshot.empty) {
                // No matching documents found, create a new booking
                const bookingData = {
                    bookingType: "Event",
                    bookObjId: eventObj.id,
                    createdBy: createdBy,
                    startDate: eventObj.startDate,
                    endDate: eventObj.endDate,
                    createdDate: isoDateString,
                    status: status,
                };
                // Add the new booking and get the generated ID
                const generatedId = await eventDataService.addNewBooking(bookingData);
                let msg = generateBookingMessage(status, eventObj, generatedId);
                let message = { subject: `RSVP - ${eventObj?.name}`, html: msg };
                const res = await eventDataService.sendEmail(userDetails.email, message);
                console.log('New booking added with ID:', generatedId);
            } else {
                // Update the existing booking
                const bookingDoc = querySnapshot.docs[0];
                const bookingDocId = bookingDoc.id;
                const bookingData = {
                    createdDate: isoDateString,
                    updatedDate: isoDateString,
                    status: status,
                };
                // Update the booking document
                await eventDataService.updateExistingBooking(bookingDocId, bookingData);
                let msg = generateBookingMessage(status, eventObj, bookingDocId);
                let message = { subject: `RSVP - ${eventObj?.name}`, html: msg };
                // Send email
                const res = await eventDataService.sendEmail(userDetails.email, message); // Call the data service
                console.log('Booking updated successfully:', bookingDocId);
            }
        } catch (error) {
            console.log('Error handling booking:', error);
            alert(error)
            props.setIsOpen(false);
        }
    }

    useEffect(() => {
        const fields = ['id'];
        setIsLoading(false)
        if (props.selectedRsvpMember && typeof (props.selectedRsvpMember) == "object") {
            let obj = props.selectedRsvpMember;
            setValue("noOfGuest", obj["noOfGuest"]);
            setValue("isAttending", obj["isAttending"]);
            setValue("userId", obj["userId"]);
            let selectedMember = props.users.filter(obj => obj.id == props.selectedRsvpMember.userId);
            if (selectedMember[0] && selectedMember[0].firstName) {
                let str = `${selectedMember[0].firstName} ${selectedMember[0].lastName}`
                setSeletedMemberPlaceholder(str);
            } else {
                setSeletedMemberPlaceholder('');
            }
        } else {
            fields.forEach(field => setValue(field, InitialRSVPState[field]));
            setSeletedMemberPlaceholder('');
        }
    }, [props.selectedRsvpMember])

    function updateSelectedMember(selobj) {
        let selectedMember = props.users.filter(obj => obj.id == selobj[0]);
        if (selectedMember[0] && selectedMember[0].firstName) {
            let str = `${selectedMember[0].firstName} ${selectedMember[0].lastName}`
            setSeletedMemberPlaceholder(str);
        } else {
            setSeletedMemberPlaceholder('');
        }
    }

    return (
        <DrawerOverlay
            size={"medium"}
            position={"right"}
            style={{ width: "504px" }}
            open={props.isOpen}
            onOpenChange={(_, { open }) => props.setIsOpen(open)}
        ><DrawerHeader style={{
            background: "#F7F7F7",
            padding: "26px 32px"
        }}>
                <DrawerHeaderTitle
                    action={
                        <Button
                            appearance="subtle"
                            aria-label="Close"
                            icon={<Dismiss24Regular />}
                            onClick={() => props.setIsOpen(false)}
                        />
                    }
                >
                    + {props.selectedRsvpMember ? "Edit" : "New"} RSVP
                </DrawerHeaderTitle>
            </DrawerHeader>
            <DrawerBody>
                <form onSubmit={handleSubmit(onSubmit)}
                    className={styles.root} onReset={reset} id={props.selectedRsvpMember ? props.selectedRsvpMember.id : "New"}>

                    <Label className={"side-drawer-field__label"}>
                        Select Member*
                    </Label>
                    <Controller
                        name="userId"
                        control={control}
                        rules={{ required: true }}

                        render={({ field }) => <Dropdown multiselect={false}
                            className={"side-drawer-field-dropdown  side-drawer-field__gap"}
                            onOptionSelect={(ev, data) => {
                                field.onChange(data.selectedOptions[0])
                                updateSelectedMember(data.selectedOptions)
                            }}
                            selectedOptions={[field.value]}
                            placeholder={selectedMemberPlaceholder || 'Member'}
                        >
                            {props.users.map((option) => (
                                <Option key={option.id} value={option.id}>
                                    {`${option.firstName} ${option.lastName}`}
                                </Option>
                            ))}
                        </Dropdown>}
                    />
                    <div className={"d-flex"} style={{ alignItems: 'center' }}>
                        <Label className={"side-drawer-field__label"}>
                            Attending
                        </Label>
                        <Controller
                            name="isAttending"
                            control={control}
                            rules={{ required: false }}
                            render={({ field }) => (
                                <div onClick={() => field.onChange(!field.value)}>
                                    <Switch checked={field.value} {...field} />
                                </div>
                            )}
                        />
                    </div>
                    <div style={{ marginTop: '10px' }} />
                    <div className={"d-flex justify-space-between"}>
                        <div style={{ display: "grid", width: "49%" }}>
                            <Label className={"side-drawer-field__label"}>Number Of Guest</Label>
                            <Controller
                                name="noOfGuest"
                                control={control}
                                rules={{ required: false }}
                                render={({ field }) => <input
                                    placeholder={"Enter Number"}
                                    className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"} {...field}
                                    type={"number"} />
                                }
                            />
                        </div>
                        <div style={{ display: "grid", width: "49%" }}>
                        </div>
                    </div>
                    <div style={{ marginTop: '120px' }} />
                    <div style={{ position: "absolute", bottom: 0, width: "90%" }}>
                        {
                            isLoading ?
                                <Spinner label="Loading..." /> :
                                <div className="btnActions">
                                    <button appearance="primary" type="submit" className="form-submit-btn pointer">
                                        {props.selectedRsvpMember ? 'Update' : 'Submit'}</button>
                                    <button className="form-submit-cancel pointer"
                                        onClick={() => props.setIsOpen(false)}>Cancel
                                    </button>
                                </div>
                        }
                    </div>
                </form>
            </DrawerBody>
        </DrawerOverlay>
    )
}

export default EventRSVPForm
function generateBookingMessage(status, eventObj, bookingId) {
    const startDtStr = eventObj.startDate; // Placeholder for any necessary date conversion
    const endDtStr = eventObj.endDate;
    let startDtStrmst = convertUtcToMst(eventObj.startDate)
    let endDtStrmst = convertUtcToMst(eventObj.endDate)
    let msg = '';
    if (status === BOOKINGSTATUS.Attending) {
        msg = `Hey there! Thank you so much for RSVPing to ${eventObj?.name}, booked from ${startDtStrmst} to ${endDtStrmst}. We're excited to have you join us. Booking Ref. ${bookingId}`;
    } else if (status === BOOKINGSTATUS.Cancel) {
        msg = `We wanted to confirm that we've received your cancellation for ${eventObj?.name}. Your RSVP has been successfully canceled. Booking Ref. ${bookingId}`;
    }

    return msg;
}
function convertUtcToMst(utcDateTimeStr) {
    // Parse the UTC datetime string into a JavaScript Date object
    const utcDate = new Date(utcDateTimeStr);

    // Options for time zone conversion
    const options = {
        timeZone: "America/Denver", // MST time zone
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true, // Use 24-hour format
    };

    // Format the date to MST
    const mstDateStr = utcDate.toLocaleString("en-US", options);

    return mstDateStr + ' MST';
}