import ExportJsonExcel from 'js-export-excel';
import Papa from "papaparse";
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const tempColumn = [
    'Customer Name',
    'Customer Email',
    'Invoice Status',
    'Invoice Number',
    'Invoice Date',
    'Subtotal',
    'Invoice Tax',
    'Total Invoiced',
    'Invoice Line Item Description',
    'Product Name',
    'Invoice Line Item Quantity',
    'Amount Due',
    'Paid Status',
    'Amount Paid',
    'Payment Status',
    'Payment Date',
    'Seller Message',
    'Payment Fee',
    'Payment Amount',
    'Amount Refunded',
    'Payout Date',
    'PaymentMetadata (other info)',
    'Payment Metadata (product info)',
    'Credit Memos'
]
function convertStripeAmount(paymentAmount) {
    // Check if paymentAmount is null, undefined, or an empty string
    if (paymentAmount === null || paymentAmount === undefined || paymentAmount === '') {
        return 0; // You can choose to return null or throw an error instead
    }
    // Ensure the paymentAmount is a number
    const amount = Number(paymentAmount);
    if (isNaN(amount)) {
        return 0; // Return 0 or handle as needed
    }
    // Divide the payment amount by 100 to convert cents to dollars
    return amount / 100;
}
function formatDate(unixTimestamp) {
    // Check if the input is null, undefined, or not a valid number
    if (!unixTimestamp || isNaN(unixTimestamp)) {
        return "Invalid Date";
    }

    // Multiply by 1000 to convert seconds to milliseconds
    const date = new Date(unixTimestamp * 1000);

    // Check if the created date is invalid (e.g., input was not a number)
    if (isNaN(date.getTime())) {
        return "Invalid Date";
    }

    const options = { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
}
export function generateExelData(dataArr, exportSelectedOption) {

    const formattedData = dataArr && dataArr.map((invoice) => {
        // Calculate the total price for all products in the item
        if (invoice) {
            return {
                'Customer Name': invoice.customer_name,
                'Customer Email': invoice.customer_email,
                'Invoice Status': invoice.status,
                'Invoice Number': invoice.number,
                'Invoice Date': formatDate(invoice.datetimestamp),
                'Subtotal': convertStripeAmount(invoice.subtotal).toFixed(2),
                'Invoice Tax': convertStripeAmount(invoice.tax) || '0',
                'Total Invoiced': convertStripeAmount(invoice.total_invoiced).toFixed(2) || '0',
                'Invoice Line Item Description': invoice.line_items?.map(item => item.description).join(', ') || '-',
                'Product Name': invoice.line_items?.map(item => item.product_name).join(', ') || '-',
                'Invoice Line Item Quantity': invoice.line_items?.map(item => item.quantity).join(', ') || '-',
                'Amount Due': convertStripeAmount(invoice.amount_due),
                'Paid Status': invoice.paid,
                'Amount Paid': convertStripeAmount(invoice.amount_paid),
                'Payment Status': invoice?.payment?.payment_status || '',
                'Payment Date': invoice?.payment?.payment_date || '',
                'Seller Message': invoice?.payment?.seller_message || '',
                'Payment Fee': convertStripeAmount(invoice?.payment?.payment_fee) || '0',
                'Payment Amount': convertStripeAmount(invoice?.payment?.payment_amount) || '0',
                'Amount Refunded': convertStripeAmount(invoice?.payment?.amount_refunded) || '0',
                'Payout Date': invoice?.payment?.payout_date || '',
                'PaymentMetadata (other info)': invoice?.payment?.payment_metadata?.otherInfo || '',
                'Payment Metadata (product info)': invoice?.payment?.payment_metadata?.productInfo || '',
                'Credit Memos': invoice?.credit_adjustments ? invoice.credit_adjustments[0]?.memo || '' : ''
            };

        }
        return {
            'Customer Name': '',
            'Customer Email': '',
            'Invoice Status': '',
            'Invoice Number': '',
            'Invoice Date': '',
            'Subtotal': '',
            'Invoice Tax': '0',
            'Total Invoiced': '0',
            'Invoice Line Item Description': '-',
            'Product Name': '-',
            'Invoice Line Item Quantity': '-',
            'Amount Due': '',
            'Paid Status': '',
            'Amount Paid': '',
            'Payment Status': '',
            'Payment Date': '',
            'Seller Message': '',
            'Payment Fee': '0',
            'Payment Amount': '0',
            'Amount Refunded': '0',
            'Payout Date': '',
            'PaymentMetadata (other info)': '',
            'Payment Metadata (product info)': '',
            'Credit Memos': ''
        };
    });

    const option = {
        fileName: 'reports',
        datas: [
            {
                sheetData: formattedData,  // Assume `formattedData` is your dataset
                sheetName: 'Orders',
                sheetFilter: [
                    'Customer Name',
                    'Customer Email',
                    'Invoice Status',
                    'Invoice Number',
                    'Invoice Date',
                    'Subtotal',
                    'Invoice Tax',
                    'Total Invoiced',
                    'Invoice Line Item Description',
                    'Product Name',
                    'Invoice Line Item Quantity',
                    'Amount Due',
                    'Paid Status',
                    'Amount Paid',
                    'Payment Status',
                    'Payment Date',
                    'Seller Message',
                    'Payment Fee',
                    'Payment Amount',
                    'Amount Refunded',
                    'Payout Date',
                    'PaymentMetadata (other info)',
                    'Payment Metadata (product info)',
                    'Credit Memos'
                ],
                sheetHeader: [
                    'Customer Name',
                    'Customer Email',
                    'Invoice Status',
                    'Invoice Number',
                    'Invoice Date',
                    'Subtotal',
                    'Invoice Tax',
                    'Total Invoiced',
                    'Invoice Line Item Description',
                    'Product Name',
                    'Invoice Line Item Quantity',
                    'Amount Due',
                    'Paid Status',
                    'Amount Paid',
                    'Payment Status',
                    'Payment Date',
                    'Seller Message',
                    'Payment Fee',
                    'Payment Amount',
                    'Amount Refunded',
                    'Payout Date',
                    'PaymentMetadata (other info)',
                    'Payment Metadata (product info)',
                    'Credit Memos'
                ],
                columnWidths: tempColumn.map(col => parseInt(col.width, 10) / 20)  // Adjust if needed for your export
            },
        ],
    };


    if (exportSelectedOption === 'Excel') {
        const toExcel = new ExportJsonExcel(option);
        toExcel.saveExcel();
    } else if (exportSelectedOption === 'CSV') {
        exportToCSV(formattedData, 'reports');
    } else if (exportSelectedOption === 'PDF') {
        exportToPDF(dataArr, 'reports');
    } else if (exportSelectedOption === 'Print') {
        //printData(formattedData);
    }
}

export function exportToCSV(data, fileName) {
    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", `${fileName}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export function exportToPDF(data, fileName) {
    const doc = new jsPDF('landscape');  // Use landscape mode for more horizontal space
    const tableColumn = tempColumn;
    const tableRows = [];

    data.forEach(invoice => {
        const row = [
            invoice.customer_name,
            invoice.customer_email,
            invoice.status,
            invoice.number,
            formatDate(invoice.datetimestamp),
            convertStripeAmount(invoice.subtotal).toFixed(2),
            convertStripeAmount(invoice.tax) || '0',
            convertStripeAmount(invoice.total_invoiced).toFixed(2) || '0',
            invoice.line_items?.map(item => item.description).join(', ') || '-',
            invoice.line_items?.map(item => item.product_name).join(', ') || '-',
            invoice.line_items?.map(item => item.quantity).join(', ') || '-',
            convertStripeAmount(invoice.amount_due),
            invoice.paid,
            convertStripeAmount(invoice.amount_paid),
            invoice?.payment?.payment_status || '',
            invoice?.payment?.payment_date || '',
            invoice?.payment?.seller_message || '',
            convertStripeAmount(invoice?.payment?.payment_fee) || '0',
            convertStripeAmount(invoice?.payment?.payment_amount) || '0',
            convertStripeAmount(invoice?.payment?.amount_refunded) || '0',
            invoice?.payment?.payout_date || '',
            invoice?.payment?.payment_metadata?.otherInfo || '',
            invoice?.payment?.payment_metadata?.productInfo || '',
            invoice?.credit_adjustments ? invoice.credit_adjustments[0]?.memo || '' : ''
        ];
        tableRows.push(row);
    });

    doc.autoTable({
        head: [tableColumn],
        body: tableRows,
        startY: 1.5,  // Adjust as needed
        styles: {
            cellPadding: 1,
            fontSize: 4,
            overflow: 'linebreak',
        },
        columnStyles: {
            0: { cellWidth: 11},
            1: { cellWidth: 16 },
            2: { cellWidth: 8 },
            3: { cellWidth: 15 },
            4: { cellWidth: 16 },
            5: { cellWidth: 10 },
            6: { cellWidth: 8 },
            7: { cellWidth: 10 },
            8: { cellWidth: 24 },
            9: { cellWidth: 10 },
            10: { cellWidth: 12 },
            11: { cellWidth: 10 },
            12: { cellWidth: 10 },
            13: { cellWidth: 10 },
            14: { cellWidth: 12 },
            15: { cellWidth: 12 },
            16: { cellWidth: 12 },
            17: { cellWidth: 10 },
            18: { cellWidth: 10 },
            19: { cellWidth: 10 },
            20: { cellWidth: 12 },
            21: { cellWidth: 12 },
            22: { cellWidth: 12 },
            23: { cellWidth: 12 },
        },
        theme: 'striped',  // Optional: adds a striped effect to the rows
        headStyles: { fillColor: [22, 160, 133] },  // Optional: change header color
    });

    doc.save(`${fileName}.pdf`);
}