import {
    Button,
    Label,
    Spinner,
    Table, TableBody,
    TableCell,
    TableCellLayout,
    TableHeader,
    TableHeaderCell,
    TableRow,
    makeStyles, shorthands,
} from "@fluentui/react-components";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import { Tag, TagGroup } from "@fluentui/react-tags";
import axios from "axios";
import { collection, getDocs, query, where } from "firebase/firestore";
import debounce from 'lodash/debounce';
import moment from 'moment';
import React, { useEffect, useRef, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import './ProductOrderHistory.css';
import CustomSearchInput2 from "./components/CustomSearchInput2";
import Layout from "./components/Layout";
import { generateExelData } from "./components/ReportUserWalletUtil";
import { Base_Url, auth, db, trackException } from "./services/firebase";
import orderHistoryDataService from "./services/orderHistoryDataService";
import userDataService from "./services/userDataService";
import { CalanderSVG, CheckBoxChecked, CheckBoxUnChecked, DownArowSVG, DownSmallArrow, FilterSVG, RadioSelected, RadioUnSelected } from "./static/svgs";

const useStyles = makeStyles({
    root: {
        ...shorthands.overflow("hidden"),
        display: "flex",
        height: "480px",
        backgroundColor: "#fff"
    },

    content: {
        ...shorthands.flex(1),
        ...shorthands.padding("16px"),
        display: "block",
        ...shorthands.gridArea("main")
    },

    breakFlex: {
        flexBasis: "100%",
        textAlign: "left"
    }
});

const columns = [
    { columnKey: "id", label: "Id", width: '18%' },
    { columnKey: "refrenceId", label: "RefrenceId", width: '18%' },
    { columnKey: "date", label: "Date", width: '15%' },
    { columnKey: "username", label: "User", width: '15%' },
    { columnKey: "type", label: "Transaction Type", width: '15%' },
    { columnKey: "amount", label: "Amount", width: '14%' },
    { columnKey: "type", label: "Type", width: '10%' },
];

function UserWalletHistory() {
    const styles = useStyles();
    const [user, loading, error] = useAuthState(auth);
    const [allTransactionHistory, setAllTransactionHistory] = useState([]);

    const [searchTerm, setSearchTerm] = useState('');
    const [filteredOrderHistory, setFilteredOrderHistory] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(30); // Number of events per page
    const [startAfterDoc, setStartAfterDoc] = useState(null); // Track the last document of the previous page
    const [isLoading, setIsLoading] = useState(false);
    const [hasMoreData, setHasMoreData] = useState(true);
    const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
    const [popupVisible, setPopupVisible] = useState(false);
    const [popupVisibleDate, setPopupVisibleDate] = useState(false);
    const [popupVisibleExport, setPopupVisibleExport] = useState(false);
    const [members, setMembers] = useState([
        { id: 1, name: 'John', selected: false },
        { id: 2, name: 'Jane', selected: false },
        { id: 3, name: 'Doe', selected: false }
    ]);
    const [searchMembers, setSearchMembers] = useState('');
    const [visibleTags, setVisibleTags] = useState([]);
    const [seletedFilterMember, setSeletedFilterMember] = useState([]);
    const firstTagRef = useRef(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedStartDate, setSelectedStartDate] = useState(null);
    const [selectedEndDate, setSelectedEndDate] = useState(null);
    const [exportSelectedOption, setExportSelectedOption] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch events when the page number changes
        if (page > 1) {
            loadMoreEvents();
        }
    }, [page]);

    const fetchTransactionHistory = async () => {
        setPage(1)
        setStartAfterDoc(null)
        setHasMoreData(true)
        try {
            if (!!user) {
                const userquery = query(collection(db, "Users"), where("email", "==", user?.email));
                const userdoc = await getDocs(userquery);
                if (userdoc.docs.length > 0) {
                    const { orderHistory, lastVisible } = await orderHistoryDataService.getAllTransactionHistoryWithPage(pageSize, null);
                    // Update the startAfterDoc for the next page
                    setStartAfterDoc(lastVisible);
                    let allEventArr = orderHistory
                    if (allEventArr.length < pageSize) {
                        setHasMoreData(false)
                    }
                    setAllTransactionHistory(allEventArr);
                    setFilteredOrderHistory(allEventArr)
                } else {
                    console.log('no access');
                }
                setIsLoading(false)
            }
        } catch (err) {
            trackException(JSON.stringify(err))
            setIsLoading(false)
            console.error(err);
            alert("An error occured while fetching user data");
        }
    };

    // Fetch charges from Stripe by date range
    const fetchStripeCharges = async (startDate, endDate) => {
        try {
            const config = {
                method: 'get',
                url: `${Base_Url}/getChargesByDateRange`,
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': `Bearer ${user.accessToken}`,
                    'Accept': "application/json"
                },
                params: { startDate, endDate }
            };

            const response = await axios.request(config);

            if (response.status === 200) {
                // Map Stripe data to a standardized format
                return response.data.charges.map(charge => ({
                    id: charge.id,
                    amount: (charge.amount / 100).toFixed(2),  // Convert amount from cents to dollars
                    createdAt: charge.created * 1000,  // Convert timestamp to milliseconds
                    description: charge.description || "No description",
                    isFromCardOrBank: true,  // Stripe charges are assumed to be from a card or bank
                    type: 'Stripe Payment',
                    source: 'Stripe'
                }));
            } else {
                console.error(response.data.message || 'Failed to fetch charges from Stripe.');
                return [];
            }
        } catch (error) {
            console.error('Error fetching Stripe charges:', error);
            alert('An error occurred while fetching charge data.');
            return [];
        }
    };


    const loadMoreEvents = async () => {
        const { orderHistory, lastVisible } = await orderHistoryDataService.getAllTransactionHistoryWithPage(pageSize, startAfterDoc);
        if (orderHistory.length < pageSize) {
            setHasMoreData(false)
            // You can handle this case, e.g., display a message
        } else {
            if (allTransactionHistory && allTransactionHistory.length > 0) {
                let mergeArr = [...allTransactionHistory, ...orderHistory]
                setAllTransactionHistory(mergeArr);
                setFilteredOrderHistory(mergeArr);
            }
        }
    };

    const fetchTransactionHistoryByUser = async (ids) => {
        setPage(1)
        setStartAfterDoc(null)
        setHasMoreData(true)
        try {
            if (!!user) {
                const { orderHistory, lastVisible } = await orderHistoryDataService.getTransactionHistoryByUserIdWithPage(ids, null, null);
                // Update the startAfterDoc for the next page
                setStartAfterDoc(lastVisible);
                let allEventArr = orderHistory
                if (allEventArr.length < pageSize) {
                    setHasMoreData(false)
                }
                setAllTransactionHistory(allEventArr);
                setFilteredOrderHistory(allEventArr)
                setIsLoading(false)
            }
        } catch (err) {
            trackException(JSON.stringify(err))
            setIsLoading(false)
            console.error(err);
            alert("An error occured while fetching user data");
        }
    };
    const fetchFilterTransactionHistory = async (selection) => {
        setPage(1)
        setStartAfterDoc(null)
        setHasMoreData(true)
        try {
            if (!!user) {
                let userIds = []
                if (visibleTags && visibleTags.length > 0) {
                    const selectedMembers = members.filter(member => member.selected);
                    userIds = selectedMembers.map(member => member.id);
                }
                const { orderHistory, lastVisible } = await orderHistoryDataService.getTransactionHistoryBySelection(selection, selectedStartDate, selectedEndDate, userIds)
                // Update the startAfterDoc for the next page
                //setStartAfterDoc(lastVisible);
                let allEventArr = orderHistory
                //setAllTransactionHistory(allEventArr);
                setFilteredOrderHistory(allEventArr)
            }
        } catch (err) {
            trackException(JSON.stringify(err))
            setIsLoading(false)
            console.error(err);
            alert("An error occured while fetching user data");
        }
    };

    const fetchUserList = async () => {
        try {
            if (!!user) {
                const usersQuery = await userDataService.getAllUser();
                let allUserList = usersQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
                setAllUsers(allUserList);
                const transformedArray = allUserList.map(obj => ({
                    id: obj.id,
                    name: `${obj.firstName} ${obj.lastName}`,
                    email: obj.email,
                    selected: false
                }));
                setMembers(transformedArray)
            }
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/");
        setIsLoading(true)
        fetchUserList()
        fetchTransactionHistory();
    }, [user, loading]);

    useEffect(() => {
        if (visibleTags && visibleTags.length > 0) {
            const selectedMembers = members.filter(member => member.selected);
            const selectedMemberids = selectedMembers.map(member => member.id);
            fetchTransactionHistoryByUser(selectedMemberids)
        } else {
            fetchTransactionHistory()
        }
    }, [visibleTags]);

    // Debounce the handleSearch function
    const debouncedSearch = debounce(search => {
        const lowerSearch = search.toLowerCase();
        const filtered = allTransactionHistory.filter(event =>
            event.id.toLowerCase().includes(lowerSearch)
        );
        setFilteredOrderHistory([]);
        setFilteredOrderHistory(filtered);
    }, 300); // Adjust the debounce delay as needed

    const handleSearch = event => {
        const value = event.target.value;
        setSearchTerm(value);
        debouncedSearch(value);
    };
    const handleClear = () => {
        setSearchTerm('');
        debouncedSearch('');
    };

    const handleSearchMember = event => {
        const value = event.target.value;
        setSearchMembers(value);
    };

    const handleOptionClick = (option) => {
        setSelectedOption(prevOption => prevOption === option ? null : option);
    };

    const handleLoadMore = () => {
        setPage(prevPage => prevPage + 1);
    };

    const removeItem = (e, { value }) => {
        setVisibleTags([...visibleTags].filter((tag) => tag.value !== value));
        setMembers(prevMembers =>
            prevMembers.map(member =>
                member.id === value ? { ...member, selected: false } : member
            )
        );
    };

    const openPopup = (event) => {
        // Calculate the position of the button
        const buttonRect = event.target.getBoundingClientRect();
        const buttonX = buttonRect.left + (buttonRect.width / 2);
        const buttonY = buttonRect.top + (buttonRect.height / 2);

        // Set the position of the popup relative to the button
        const popupWidth = 315;
        const popupHeight = 356;
        setPopupPosition({ x: buttonX, y: buttonY + 10 }); // Adjust 10px downward for better alignment

        // Show the popup
        setPopupVisible(true);
        setPopupVisibleDate(false);
        setPopupVisibleExport(false)
    };
    const openExportPopup = (event) => {
        // Calculate the position of the button
        const buttonRect = event.target.getBoundingClientRect();
        const buttonX = buttonRect.left - 100 //+ (buttonRect.width / 2);
        const buttonY = buttonRect.top + (buttonRect.height / 2);
        setPopupPosition({ x: buttonX, y: buttonY + 10 }); // Adjust 10px downward for better alignment
        // Show the popup
        setPopupVisible(false);
        setPopupVisibleDate(false);
        setPopupVisibleExport(true)
    };

    const openDatePopup = (event) => {
        // Calculate the position of the button
        const buttonRect = event.target.getBoundingClientRect();
        const buttonX = buttonRect.left + (buttonRect.width / 2);
        const buttonY = buttonRect.top + (buttonRect.height / 2);

        // Set the position of the popup relative to the button
        setPopupPosition({ x: buttonX, y: buttonY + 10 }); // Adjust 10px downward for better alignment

        // Show the popup
        setPopupVisible(false);
        setPopupVisibleExport(false)
        setPopupVisibleDate(true);
    };

    // Function to handle checkbox toggle
    const toggleSelect = (id) => {
        setMembers((prevMembers) =>
            prevMembers.map((member) =>
                member.id === id ? { ...member, selected: !member.selected } : member
            )
        );
    };

    const handleApplyMemberFilter = () => {
        // Update visible tags
        const selectedMembers = members.filter(member => member.selected);
        const updatedTags = selectedMembers.map(member => ({
            value: member.id,
            children: member.name
        }));
        setVisibleTags(updatedTags);
        // Close the popup
        setPopupVisible(false);
    };

    const handleCancelMemberFilter = () => {
        // Close the popup
        setPopupVisible(false);
        setPopupVisibleDate(false);
    };

    const handleCancel = () => {
        // Close the popup
        setSelectedOption(null)
        setFilteredOrderHistory(allTransactionHistory)
        setPopupVisible(false);
        setPopupVisibleDate(false);
    };

    const handleCancelExport = () => {
        // Close the popup
        setPopupVisibleExport(false)
    };

    const handleDateApply = () => {
        if (selectedOption) {
            fetchFilterTransactionHistory(selectedOption)
            setPopupVisible(false);
            setPopupVisibleDate(false);
        } else {
            setPopupVisible(false);
            setPopupVisibleDate(false);
        }
    }

    const handleDateSelect = (date) => {
        setSelectedOption("CustomDate")
        setSelectedStartDate(date);
    };
    const handleDateSelect2 = (date) => {
        setSelectedOption("CustomDate")
        setSelectedEndDate(date);
    };

    const handleExportChange = (option) => {
        setExportSelectedOption(option);
    };

    function onGenerateExportClick() {
        const dataArr = filteredOrderHistory;
        generateExelData(dataArr, exportSelectedOption, allUsers)
        setPopupVisibleExport(false)
    }

    return (
        <Layout>
            <div className={styles.content}>
                <div className={"d-flex justify-space-between"}>
                    <div className={"d-flex"}>
                        <div>
                            <FilterSVG />
                        </div>
                        <div className={'memberNameDiv'} onClick={openPopup}>
                            <span>MEMBER NAME</span>
                            <DownArowSVG />
                        </div>
                        <div className={'memberNameDiv'} onClick={openDatePopup}>
                            <span>Order Date</span>
                            <DownArowSVG />
                        </div>
                    </div>
                    <div className={"d-flex"}>
                        <div className={"exportbtn"} onClick={openExportPopup}>
                            <span>Generate report</span>
                            <DownSmallArrow />
                        </div>
                    </div>
                    {/* <CustomSearchInput
                        value={searchTerm}
                        onChange={handleSearch}
                        onClick={handleClear}
                    /> */}
                </div>
                <div style={{ marginTop: '10px' }}>
                    {visibleTags.length !== 0 && (
                        <TagGroup onDismiss={removeItem} aria-label="Dismiss example">
                            {visibleTags.map((tag, index) => (
                                <Tag
                                    dismissible
                                    dismissIcon={{ "aria-label": "remove" }}
                                    value={tag.value}
                                    key={tag.value}
                                    ref={index === 0 ? firstTagRef : null}
                                >
                                    {tag.children}
                                </Tag>
                            ))}
                        </TagGroup>
                    )}
                </div>

                <div className={"table-layout"}>
                    <Table aria-label="Table with cell actions">
                        <TableHeader className={"list-row"}>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableHeaderCell style={{ width: column.width }} key={column.columnKey}>
                                        <span className={"listHeaderText"}>{column.label}</span>
                                    </TableHeaderCell>
                                ))}
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {filteredOrderHistory.length > 0 && filteredOrderHistory.map((item) => {
                                return (
                                    <TableRow key={item.id}>
                                        <TableCell className={"table-row-fonts__primary"}>
                                            <TableCellLayout>
                                                <div style={{ lineBreak: 'anywhere' }}>{item.id}</div>
                                            </TableCellLayout>
                                        </TableCell>
                                        <TableCell className={"table-row-fonts__desc"}>
                                            <TableCellLayout>
                                                <div style={{ lineBreak: 'anywhere' }}>{item.refId}</div>
                                            </TableCellLayout>
                                        </TableCell>
                                        <TableCell className={"table-row-fonts__desc"}>
                                            <TableCellLayout>
                                                {item.createdAt ? moment(item.createdAt.toDate()).format("MMM/DD/YYYY h:mm a") : ''}
                                                {/* {item.userId} */}
                                            </TableCellLayout>
                                        </TableCell>
                                        <TableCell className={"table-row-fonts__desc"}>
                                            <TableCellLayout>
                                                <Label>{allUsers.filter(t => t.id == item.userId)[0]?.firstName} &nbsp;
                                                    {allUsers.filter(t => t.id == item.userId)[0]?.lastName}</Label>
                                            </TableCellLayout>
                                        </TableCell>
                                        <TableCell className={"table-row-fonts__desc"}>
                                            <TableCellLayout>
                                                {item.type}
                                            </TableCellLayout>
                                        </TableCell>
                                        <TableCell className={"table-row-fonts__desc"}>
                                            <TableCellLayout>
                                                {
                                                    item.isFromCardOrBank ?
                                                        <div>
                                                            <div style={{ color: 'red' }}>- ${item && item.amount ? parseFloat(item?.amount).toFixed(2) : 0}</div>
                                                        </div> :
                                                        <div>
                                                            {
                                                                item.creditType == 'Credit' ?
                                                                    item.points && item.points > 0 ? <div style={{ color: 'green' }}>+ ${item.points * 20}</div>
                                                                        :
                                                                        <div style={{ color: 'green' }}>+ ${item.amount}</div> :
                                                                    <div style={{ color: 'red' }}>- ${item && item.amount ? parseFloat(item?.amount).toFixed(2) : 0}</div>
                                                            }
                                                        </div>
                                                }
                                            </TableCellLayout>
                                        </TableCell>
                                        <TableCell className={"table-row-fonts__desc"}>
                                            <TableCellLayout>
                                                {
                                                    item.isFromCardOrBank ?
                                                        <div>
                                                            <div style={{ color: 'black' }}>Credit Card</div>
                                                        </div> :
                                                        <div>
                                                            <div style={{ color: 'black' }}>Balance</div>
                                                        </div>
                                                }
                                            </TableCellLayout>
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                            )}
                        </TableBody>
                    </Table>
                </div>
                <div style={{ marginTop: '10px', textAlign: 'center' }}>
                    {isLoading && <Spinner />} {/* Render loader if loading state is true */}
                    {!isLoading && hasMoreData && searchTerm == "" && visibleTags.length < 1 && selectedOption == null && filteredOrderHistory.length > 0 && <Button onClick={handleLoadMore}>Load More...</Button>} {/* Render button if not loading */}
                </div>
                {popupVisible && (
                    <div className="popupmain" style={{ position: 'absolute', top: popupPosition.y, left: popupPosition.x }}>
                        <CustomSearchInput2
                            value={searchMembers}
                            onChange={handleSearchMember}
                            onClick={handleClear}
                        />
                        <div className="popupMemberList">
                            {members
                                .filter(member => member.name.toLowerCase().includes(searchMembers))
                                .map((member) => (
                                    <div key={member.id}>
                                        <Label style={{ marginBottom: '4px' }} className="checkLabeldiv">
                                            <div
                                                onClick={() => toggleSelect(member.id)}
                                                style={{ cursor: 'pointer', display: 'inline-flex', alignItems: 'center' }}
                                            >
                                                {member.selected ? (
                                                    <CheckBoxChecked />  // Your checked SVG
                                                ) : (
                                                    <CheckBoxUnChecked />  // Your unchecked SVG
                                                )}
                                                <div style={{ marginLeft: '8px' }}>{member.name}</div>
                                            </div>
                                        </Label>
                                    </div>
                                ))}
                        </div>
                        <div className="buttonContainer">
                            <button className="borderButton" onClick={handleCancelMemberFilter}>Cancel</button>
                            <button className="fillButton" onClick={handleApplyMemberFilter}>APPLY</button>
                        </div>
                    </div>
                )}
                {popupVisibleExport && (
                    <div className="popupexport" style={{ position: 'absolute', top: popupPosition.y + 12, right: 40 }}>
                        <div>
                            <div className="radioFrame" onClick={() => handleExportChange('CSV')}>
                                {exportSelectedOption === 'CSV' ? <RadioSelected /> : <RadioUnSelected />}
                                <div className="radioBtnText">CSV</div>
                            </div>
                            <div className="radioFrame" onClick={() => handleExportChange('PDF')}>
                                {exportSelectedOption === 'PDF' ? <RadioSelected /> : <RadioUnSelected />}
                                <div className="radioBtnText">PDF</div>
                            </div>
                            <div className="radioFrame" onClick={() => handleExportChange('Excel')}>
                                {exportSelectedOption === 'Excel' ? <RadioSelected /> : <RadioUnSelected />}
                                <div className="radioBtnText">Excel</div>
                            </div>
                        </div>
                        <div className="buttonContainer">
                            <button className="borderButton" onClick={handleCancelExport}>Cancel</button>
                            <button className="fillButton" onClick={() => { onGenerateExportClick() }}>EXPORT</button>
                        </div>
                    </div>
                )}

                {popupVisibleDate && (
                    <div className="popupmain" style={{ position: 'absolute', top: popupPosition.y + 10, left: popupPosition.x - 50 }}>
                        <div className="dateFramesContainer">
                            <div style={{ width: '47%' }}>
                                <span>Start Date</span>
                                <DatePicker
                                    showTimeSelect
                                    selected={selectedStartDate}
                                    contentAfter={<CalanderSVG />}
                                    onSelectDate={(e) => handleDateSelect(e)}
                                    style={{
                                        width: "100%",
                                        height: "44px"
                                    }}
                                    placeholder="Start Date"
                                />
                            </div>
                            <div style={{ marginLeft: '3px', marginRight: '3px', marginTop: '16px', width: '18px', height: '1px', backgroundColor: '#C1C1C1' }}></div> {/* Add a separate element for the dash line */}
                            <div style={{ width: '47%' }}>
                                <span>End Date</span>
                                <DatePicker
                                    showTimeSelect
                                    selected={selectedEndDate}
                                    contentAfter={<CalanderSVG />}
                                    onSelectDate={(e) => handleDateSelect2(e)}
                                    style={{
                                        width: "100%",
                                        height: "44px"
                                    }}
                                    placeholder="End Date"
                                />
                            </div>
                        </div>

                        <div style={{ marginTop: '10px' }} className="seperateLine"></div>

                        {["Today", "Yesterday", "This month", "Past month"].map(option => (
                            <div
                                key={option}
                                className={`todayBtn${selectedOption === option ? " selected" : ""}`}
                                onClick={() => handleOptionClick(option)}
                            >
                                {option}
                            </div>
                        ))}
                        <div className="seperateLine"></div>
                        <div className="buttonContainer">
                            <button className="borderButton" onClick={handleCancel}>Cancel</button>
                            <button className="fillButton" onClick={handleDateApply}>APPLY</button>
                        </div>
                    </div>
                )}
            </div>
        </Layout>
    )
}

export default UserWalletHistory;