import {
    Button,
    Dropdown,
    Label,
    Option,
    Textarea,
    makeStyles, shorthands
} from "@fluentui/react-components";
import {
    DrawerBody,
    DrawerHeader,
    DrawerHeaderTitle,
    DrawerOverlay,
} from "@fluentui/react-components/unstable";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import { DeleteFilled, DeleteRegular, Dismiss24Regular, bundleIcon } from "@fluentui/react-icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Controller, useForm } from "react-hook-form";
import BookingDataService from "../../services/bookingDataService";
import NotificationDataService from "../../services/notificationDataService";
import { DateSVG } from "../../static/svgs";
import { mergeDateTime, convertToMST1, getTimeFromDate } from "../../Helper";

const DeleteIcon = bundleIcon(DeleteFilled, DeleteRegular);

export const InitialRoomState = {
    "memberId": "",
    "bookObjId": "",
    "bookingType": "Room",
    "description": "",
    "startDate": "",
    "endDate": "",
    "id": "",
    "roomType": "",
    "roomTypeId": "",
    "status": "",
    "createdBy": "",
    "createdDate": new Date().toISOString(),
};

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        // Use 2px gap below the label (per the design system)
        ...shorthands.gap("2px")
    },
    control: {
        maxWidth: "300px",
    },
});

function RoomForm(props) {
    const styles = useStyles();
    const { handleSubmit, control, reset, setValue, formState: { isDirty, dirtyFields } } = useForm({
        defaultValues: props.selectedRoom ? props.selectedRoom : InitialRoomState
    });
    const [selectedMemberPlaceholder, setSeletedMemberPlaceholder] = useState('');
    const onSubmit = async formdata => {
        var data = formdata;

        var id = "";
        const mergedstartDateTime = mergeDateTime(data.startDate, data.startTime);
        const mergedendDateTime = mergeDateTime(data.endDate, data.endTime);
        data.startDate = moment(mergedstartDateTime).toISOString();
        data.endDate = moment(mergedendDateTime).toISOString();
        let selectedRoomType = props.roomType.filter(obj => obj.id == data.roomTypeId);
        if (selectedRoomType && selectedRoomType.length > 0) {
            data.roomType = selectedRoomType[0].name
        }
        if (props.selectedRoom) {
            id = props.selectedRoom.id;
            await BookingDataService.updateBooking(props.selectedRoom.id, data);
        } else {
            data.createdBy = props.createdBy;
            const addeddata = await BookingDataService.addBooking(data);
            id = addeddata.id;
        }
        data.id = id;
        sendEmailToMember(data)
        props.setIsOpen(false);
        props.updateGrid();
    }

    async function sendEmailToMember(data) {
        let selectedMember = props.users.filter(obj => obj.id == data.memberId);

        if (selectedMember[0] && selectedMember[0].email) {
            console.log(data.startDate)
            let startDtStr = convertToMST1(data.startDate)
            let endDtStr = convertToMST1(data.endDate)
            let username = `${selectedMember[0].firstName} ${selectedMember[0].lastName}`
            let message = {
                subject: `New Room Reservation Request - ${data.roomType}`,
                html: `We are happy to inform you that your booking for ${data.roomType} is confirmed! <br>Room Reservation from ${startDtStr} to ${endDtStr}.`,
            }
            let emailId = selectedMember[0].email
            let mailData = {
                to: emailId,
                bcc: ['alexa@warehousepc.com',
                    'luke@warehousepc.com',
                    'jonas@warehousepc.com'],
                message: message,
            }
            await NotificationDataService.addEmail(mailData)
        }
    }

    useEffect(() => {
        const fields = ['bookObjId', 'bookingType', 'description', 'startDate',
            'endDate', 'id', 'roomType', 'status', 'createdBy', 'createdDate', 'startTime', 'endTime'];
        if (props.selectedRoom) {
            fields.forEach(field => setValue(field, props.selectedRoom[field]));
            setValue('startDate', moment(props.selectedRoom['startDate']).toDate());
            setValue('endDate', moment(props.selectedRoom['endDate']).toDate());

            const startTime = getTimeFromDate(props.selectedRoom.startDate);
            setValue('startTime', startTime);
            const endTime = getTimeFromDate(props.selectedRoom.endDate);
            setValue('endTime', endTime);

            let selectedMember = props.users.filter(obj => obj.id == props.selectedRoom.memberId);
            if (selectedMember[0] && selectedMember[0].firstName) {
                let str = `${selectedMember[0].firstName} ${selectedMember[0].lastName}`
                setSeletedMemberPlaceholder(str);
            } else {
                setSeletedMemberPlaceholder('');
            }
        } else {
            fields.forEach(field => setValue(field, InitialRoomState[field]));
            setSeletedMemberPlaceholder('');
        }
    }, [props.selectedRoom])

    return (
        <DrawerOverlay
            size={"medium"}
            style={{ width: "504px" }}
            position={"right"}
            open={props.isOpen}
            onOpenChange={(_, { open }) => props.setIsOpen(open)}
        ><DrawerHeader style={{
            background: "#F7F7F7",
            padding: "26px 32px"
        }}>
                <DrawerHeaderTitle
                    action={
                        <Button
                            appearance="subtle"
                            aria-label="Close"
                            icon={<Dismiss24Regular />}
                            onClick={() => props.setIsOpen(false)}
                        />
                    }
                >
                    + {props.selectedRoom ? "Edit" : "New"} Room
                </DrawerHeaderTitle>
            </DrawerHeader>
            <DrawerBody>
                <form onSubmit={handleSubmit(onSubmit)}
                    className={styles.root} onReset={reset} id={props.selectedRoom ? props.selectedRoom.id : "New"}>
                    <Label className={"side-drawer-field__label"} style={{ marginTop: "32px" }}>
                        Room Type*
                    </Label>
                    <Controller
                        name="roomTypeId"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => <Dropdown multiselect={false}
                            placeholder={'Select Room Type'}
                            onOptionSelect={(ev, data) => field.onChange(data.selectedOptions[0])}
                            className={"side-drawer-field-dropdown  side-drawer-field__gap"}
                            selectedOptions={[field.value]}>
                            {props.roomType.map((option) => (
                                <Option key={option.id} value={option.id}>
                                    {option.name}
                                </Option>
                            ))}
                        </Dropdown>}
                    />
                    <Label className={"side-drawer-field__label"}>
                        Description
                    </Label>
                    <Controller
                        name="description"
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => <Textarea {...field} resize="vertical"
                            placeholder={'Enter Description'}
                            className={" side-drawer-field-Textarea side-drawer-field__gap side-drawer-field__gap"}
                            style={{ borderRadius: "16px !important" }}

                        />}
                    />
                    <div className={"d-flex justify-space-between"}>
                        <div style={{ display: "grid", width: "49%" }}>
                            <Label className={"side-drawer-field__label"} style={
                                {
                                    marginTop: "24px"
                                }
                            }>
                                Start Date
                            </Label>
                            <Controller
                                name="startDate"
                                control={control}
                                render={({ field }) => <div className={"datepicker-custom-wrap"}><DatePicker
                                    showTimeSelect
                                    selected={field.value}
                                    contentAfter={<DateSVG />}
                                    onSelectDate={field.onChange}
                                    style={{
                                        width: "100%",
                                        height: "44px"
                                    }}
                                    placeholder="Select Start Date"
                                    className={"side-drawer-field-default  side-drawer-field__gap side-drawer-field__gap"}

                                    {...field}
                                    dateFormat={'do MMMM yyyy hh:mm a'}
                                /></div>}
                            />
                        </div>
                        <div style={{ display: "grid", width: "49%" }}>
                            <Label className={"side-drawer-field__label"} style={
                                {
                                    marginTop: "24px"
                                }
                            }>
                                Start Time
                            </Label>
                            <Controller
                                name="startTime"
                                control={control}
                                render={({ field }) => <input className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"} type="time" onChange={field.onChange}{...field} />}
                            />
                        </div>


                    </div>

                    <div className={"d-flex justify-space-between"}>
                        <div style={{ display: "grid", width: "49%" }}>
                            <Label className={"side-drawer-field__label"}>
                                End Date
                            </Label>
                            <Controller
                                name="endDate"
                                control={control}
                                render={({ field }) => <div className={"datepicker-custom-wrap"}><DatePicker
                                    showTimeSelect
                                    selected={field.value}
                                    contentAfter={<DateSVG />}
                                    onSelectDate={field.onChange}
                                    style={{
                                        width: "100%",
                                        height: "44px"
                                    }}
                                    placeholder="Select End Date"
                                    className={"side-drawer-field-default  side-drawer-field__gap side-drawer-field__gap"}
                                    {...field}
                                    dateFormat={'do MMMM yyyy hh:mm a'}
                                /></div>}
                            />
                        </div>
                        <div style={{ display: "grid", width: "49%" }}>
                            <Label className={"side-drawer-field__label"} style={
                                {}
                            }>
                                End Time
                            </Label>
                            <Controller
                                name="endTime"
                                control={control}
                                render={({ field }) => <input className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"} type="time" onChange={field.onChange}{...field} />}
                            />

                        </div>
                    </div>



                    <Label className={"side-drawer-field__label"}>
                        Member
                    </Label>
                    <Controller
                        name="memberId"
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => <Dropdown multiselect={false}
                            className={"side-drawer-field-dropdown  side-drawer-field__gap"}
                            onOptionSelect={(ev, data) => {
                                field.onChange(data.selectedOptions[0])
                            }}
                            selectedOptions={[field.value]}
                            placeholder={"Select Member"}
                        >
                            {props.users.map((option) => (
                                <Option key={option.id} value={option.id}>
                                    {`${option.firstName} ${option.lastName}`}
                                </Option>
                            ))}
                        </Dropdown>}
                    />
                    {/*<div style={{position: "absolute", bottom: 0, width: "90%"}}>*/}
                    <div className="btnActions">
                        <button appearance="primary" type="submit" className="form-submit-btn pointer">
                            {props.selectedRoom ? 'Update' : 'Submit'}</button>
                        <button className="form-submit-cancel pointer"
                            onClick={() => props.setIsOpen(false)}>Cancel
                        </button>
                    </div>
                    {/*</div>*/}
                </form>
            </DrawerBody>
        </DrawerOverlay>
    )
}

export default RoomForm