import {
    Button,
    Dropdown,
    Label,
    Option,
    Spinner,
    makeStyles, shorthands,
    useId
} from "@fluentui/react-components";
import {
    DrawerBody,
    DrawerHeader,
    DrawerHeaderTitle,
    DrawerOverlay,
} from "@fluentui/react-components/unstable";
import { Dismiss24Regular } from "@fluentui/react-icons";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Controller, useForm } from "react-hook-form";
import roomsDataService from "../../services/roomsDataService";
import { SwitchOffSVG, SwitchOnSVG } from "../../static/svgs";

export const InitialRoomState = {
    "startDay": "",
    "endDay": "",
    "id": "",
    "hoursType": "",
    "createdDate": new Date().toISOString(),
    "dateRangeOn": false,
    "type": "",
    "startTime": "",
    "endTime": "",
    "dayOfWeek": ""
};

const hoursType = [
    { id: "restaurantBar", name: "Restaurant & Bar Hours" },
    { id: "clubHours", name: "Club Hours" },
];
const getTypeName = (dayId) => {
    const day = hoursType.find(d => d.id === dayId);
    return day ? day.name : dayId; // Return the name if found, otherwise return the original ID
};
// Days of the week dropdown options
const daysOfWeek = [
    { id: "monday", name: "Monday" },
    { id: "tuesday", name: "Tuesday" },
    { id: "wednesday", name: "Wednesday" },
    { id: "thursday", name: "Thursday" },
    { id: "friday", name: "Friday" },
    { id: "saturday", name: "Saturday" },
    { id: "sunday", name: "Sunday" },
];
const getDayName = (dayId) => {
    const day = daysOfWeek.find(d => d.id === dayId);
    return day ? day.name : dayId; // Return the name if found, otherwise return the original ID
};
const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        ...shorthands.gap("2px")
    },
    control: {
        maxWidth: "300px",
    },
});

function OperatingHoursForm(props) {
    const dropdownId = useId("dropdown-default");
    const styles = useStyles();
    const { handleSubmit, control, reset, setValue, formState: { isDirty, dirtyFields } } = useForm({
        defaultValues: props.selectedOperatingHour ? props.selectedOperatingHour : InitialRoomState
    });
    const [dateRangeOn, setDateRangeOn] = useState(false);
    const [selectedHoursTypePlaceholder, setSelectedHoursTypePlaceholder] = useState('');
    const [startDayPlaceholder, setStartDayPlaceholder] = useState('');
    const [endDayPlaceholder, setEndDayPlaceholder] = useState('');
    const [dayOfWeekPlaceholder, setDayOfWeekPlaceholder] = useState('');
    const [showLoader, setShowLoader] = useState(false);

    const onSubmit = async formdata => {
        setShowLoader(true)
        let data = formdata;

        data.dateRangeOn = dateRangeOn;
        data.type = "OperatingHours";
        let id = "";
        if (props.selectedOperatingHour) {
            id = props.selectedOperatingHour.id;
            data.createdBy = props.createdBy;
            await roomsDataService.updateOperatingHours(id, data);
        } else {
            data.createdBy = props.createdBy;
            const addeddata = await roomsDataService.addOperatingHours(data);
            id = addeddata.id;
            data.id = id;
            let adata = await roomsDataService.updateOperatingHours(id, data);
        }
        setShowLoader(false)
        props.setIsOpen(false);
        props.updateGrid();
    };

    useEffect(() => {
        const fields = ['id', 'hoursType', 'startDay', 'endDay', 'dayOfWeek', 'startTime', 'endTime'];
        if (props.selectedOperatingHour) {
            fields.forEach(field => setValue(field, props.selectedOperatingHour[field]));
            setDateRangeOn(props.selectedOperatingHour['dateRangeOn'])
            setSelectedHoursTypePlaceholder(`${getTypeName(props.selectedOperatingHour['hoursType'])}`)

            if (props.selectedOperatingHour['startDay']) {
                setStartDayPlaceholder(getDayName(props.selectedOperatingHour['startDay']))
            }
            if (props.selectedOperatingHour['endDay']) {
                setEndDayPlaceholder(getDayName(props.selectedOperatingHour['endDay']))
            }
            if (props.selectedOperatingHour['dayOfWeek']) {
                setDayOfWeekPlaceholder(getDayName(props.selectedOperatingHour['dayOfWeek']))
            }
        } else {
            fields.forEach(field => setValue(field, InitialRoomState[field]));
            setSelectedHoursTypePlaceholder('')
            setStartDayPlaceholder('')
            setEndDayPlaceholder('')
            setDayOfWeekPlaceholder('')
        }
    }, [props.selectedOperatingHour])


    return (
        <DrawerOverlay
            size={"medium"}
            style={{ width: "504px" }}
            position={"right"}
            open={props.isOpen}
            onOpenChange={(_, { open }) => props.setIsOpen(open)}
        >
            <DrawerHeader
                style={{
                    background: "#F7F7F7",
                    padding: "26px 32px",
                }}
            >
                <DrawerHeaderTitle
                    action={
                        <Button
                            appearance="subtle"
                            aria-label="Close"
                            icon={<Dismiss24Regular />}
                            onClick={() => props.setIsOpen(false)}
                        />
                    }
                >
                    + {props.selectedOperatingHour ? "Edit" : "New"} Operating Hours
                </DrawerHeaderTitle>
            </DrawerHeader>

            {/* Flexbox container to align content */}
            <DrawerBody style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={styles.root}
                    onReset={reset}
                    id={props.selectedOperatingHour ? props.selectedOperatingHour.id : "New"}
                    style={{ flexGrow: 1, overflowY: 'auto' }} // Allow form to grow and scroll if content overflows
                >
                    <Label className={"side-drawer-field__label"} style={{ marginTop: "32px" }}>
                        Hours Type*
                    </Label>
                    <Controller
                        name="hoursType"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) =>
                            <Dropdown
                                multiselect={false}
                                className={"side-drawer-field-dropdown side-drawer-field__gap"}
                                placeholder={selectedHoursTypePlaceholder ? selectedHoursTypePlaceholder : "Select Hours Type"}
                                onOptionSelect={(ev, data) => {
                                    field.onChange(data.selectedOptions[0])
                                    setSelectedHoursTypePlaceholder(data.optionText);
                                }}
                                selectedOptions={field.value ? [field.value] : []}  // Properly display selected option
                            >
                                {hoursType.map((option) => (
                                    <Option key={option.id} value={option.id}>
                                        {option.name}
                                    </Option>
                                ))}
                            </Dropdown>
                        }
                    />
                    <Label className={"side-drawer-field__label"}>
                        Date Range
                    </Label>
                    <div onClick={() => { setDateRangeOn(!dateRangeOn) }}>
                        {dateRangeOn ? <SwitchOnSVG /> : <SwitchOffSVG />}
                    </div>

                    {dateRangeOn ? (
                        <div>
                            <div className={"d-flex justify-space-between"}>
                                <div style={{ display: "grid", width: "49%" }}>
                                    <Label className={"side-drawer-field__label"}>Start Day*</Label>
                                    <Controller
                                        name="startDay"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) =>
                                            <Dropdown
                                                multiselect={false}
                                                className={"side-drawer-field-dropdown side-drawer-field__gap"}
                                                placeholder={startDayPlaceholder ? startDayPlaceholder : "Select Start Day"}
                                                onOptionSelect={(ev, data) => {
                                                    field.onChange(data.selectedOptions[0]);
                                                    setStartDayPlaceholder(data.optionText);
                                                }}
                                                selectedOptions={field.value ? [field.value] : []}
                                            >
                                                {daysOfWeek.map((option) => (
                                                    <Option key={option.id} value={option.id}>
                                                        {option.name}
                                                    </Option>
                                                ))}
                                            </Dropdown>
                                        }
                                    />
                                </div>
                                <div style={{ display: "grid", width: "49%" }}>
                                    <Label className={"side-drawer-field__label"}>End Day*</Label>
                                    <Controller
                                        name="endDay"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) =>
                                            <Dropdown
                                                multiselect={false}
                                                className={"side-drawer-field-dropdown side-drawer-field__gap"}
                                                placeholder={endDayPlaceholder ? endDayPlaceholder : "Select End Day"}
                                                onOptionSelect={(ev, data) => {
                                                    field.onChange(data.selectedOptions[0]);
                                                    setEndDayPlaceholder(data.optionText);
                                                }}
                                                selectedOptions={field.value ? [field.value] : []}
                                            >
                                                {daysOfWeek.map((option) => (
                                                    <Option key={option.id} value={option.id}>
                                                        {option.name}
                                                    </Option>
                                                ))}
                                            </Dropdown>
                                        }
                                    />
                                </div>
                            </div>

                            <div className={"d-flex justify-space-between"}>
                                <div style={{ display: "grid", width: "49%" }}>
                                    <Label className={"side-drawer-field__label"}>Opening Time*</Label>
                                    <Controller
                                        name="startTime"
                                        control={control}
                                        render={({ field }) => (
                                            <input
                                                className={"side-drawer-field side-drawer-field__gap"}
                                                type="time"
                                                onChange={field.onChange}
                                                {...field}
                                            />
                                        )}
                                    />
                                </div>
                                <div style={{ display: "grid", width: "49%" }}>
                                    <Label className={"side-drawer-field__label"}>Closing Time*</Label>
                                    <Controller
                                        name="endTime"
                                        control={control}
                                        render={({ field }) => (
                                            <input
                                                className={"side-drawer-field side-drawer-field__gap"}
                                                type="time"
                                                onChange={field.onChange}
                                                {...field}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div className={"d-flex justify-space-between"}>
                                <div style={{ display: "grid", width: "100%" }}>
                                    <Label className={"side-drawer-field__label"} style={{ marginTop: "24px" }}>
                                        Day of the Week*
                                    </Label>
                                    <Controller
                                        name="dayOfWeek"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) =>
                                            <Dropdown
                                                multiselect={false}
                                                className={"side-drawer-field-dropdown side-drawer-field__gap"}
                                                placeholder={dayOfWeekPlaceholder ? dayOfWeekPlaceholder : "Select Day of the Week"}
                                                onOptionSelect={(ev, data) => {
                                                    field.onChange(data.selectedOptions[0]);
                                                    setDayOfWeekPlaceholder(data.optionText);
                                                }}
                                                selectedOptions={field.value ? [field.value] : []}
                                            >
                                                {daysOfWeek.map((option) => (
                                                    <Option key={option.id} value={option.id}>
                                                        {option.name}
                                                    </Option>
                                                ))}
                                            </Dropdown>
                                        }
                                    />
                                </div>
                            </div>

                            <div className={"d-flex justify-space-between"}>
                                <div style={{ display: "grid", width: "49%" }}>
                                    <Label className={"side-drawer-field__label"}>Opening Time*</Label>
                                    <Controller
                                        name="startTime"
                                        control={control}
                                        render={({ field }) => (
                                            <input
                                                className={"side-drawer-field side-drawer-field__gap"}
                                                type="time"
                                                onChange={field.onChange}
                                                {...field}
                                            />
                                        )}
                                    />
                                </div>
                                <div style={{ display: "grid", width: "49%" }}>
                                    <Label className={"side-drawer-field__label"}>Closing Time*</Label>
                                    <Controller
                                        name="endTime"
                                        control={control}
                                        render={({ field }) => (
                                            <input
                                                className={"side-drawer-field side-drawer-field__gap"}
                                                type="time"
                                                onChange={field.onChange}
                                                {...field}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Button Section: This will be pushed to the bottom */}
                    <div className="btnActions" style={{ marginTop: 'auto' }}>
                        {showLoader ? (
                            <Spinner label="Loading..." />
                        ) : (
                            <>
                                <button appearance="primary" type="submit" className="form-submit-btn pointer">
                                    {props.selectedOperatingHour ? "Update" : "Submit"}
                                </button>
                                <button
                                    className="form-submit-cancel pointer"
                                    onClick={() => props.setIsOpen(false)}
                                >
                                    Cancel
                                </button>
                            </>
                        )}
                    </div>
                </form>
            </DrawerBody>
        </DrawerOverlay>
    );

}

export default OperatingHoursForm;