import ExportJsonExcel from 'js-export-excel';
import Papa from "papaparse";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { format } from 'date-fns';

const tempColumn = [
    'Id',
    'Note',
    'Email',
    'IsGuest',
    'Status',
    'Amount',
    'Created Time',
    'LineItems',
]
function convertStripeAmount(paymentAmount) {
    // Check if paymentAmount is null, undefined, or an empty string
    if (paymentAmount === null || paymentAmount === undefined || paymentAmount === '') {
        return 0; // You can choose to return null or throw an error instead
    }
    // Ensure the paymentAmount is a number
    const amount = Number(paymentAmount);
    if (isNaN(amount)) {
        return 0; // Return 0 or handle as needed
    }
    // Divide the payment amount by 100 to convert cents to dollars
    return amount / 100;
}
function formatDate(unixTimestamp) {
    // Check if the input is null, undefined, or not a valid number
    if (!unixTimestamp || isNaN(unixTimestamp)) {
        return "Invalid Date";
    }

    // Multiply by 1000 to convert seconds to milliseconds
    const date = new Date(unixTimestamp * 1000);

    // Check if the created date is invalid (e.g., input was not a number)
    if (isNaN(date.getTime())) {
        return "Invalid Date";
    }

    const options = { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
}

export function generateExelData(dataArr, exportSelectedOption) {

    const formattedData = dataArr && dataArr.map((invoice) => {
        // Calculate the total price for all products in the item
        if (invoice) {
            //let lineArr = invoice.lineItems && invoice.lineItems.elements ? invoice.lineItems.elements : []
            // let products = lineArr.map(item => (
            //     `${item.name} / $${(item.price / 100).toFixed(2)}`
            // )).join(',\n')
            return {
                'Id': invoice.id,
                'Note': invoice?.note,
                'Email': invoice?.user?.email || '',
                'IsGuest': invoice.user.guest ? 'YES' : 'NO',
                'Status': invoice?.paymentState,
                'Amount': convertStripeAmount(invoice.total).toFixed(2),
                'Created Time': format(new Date(invoice.createdTime), 'dd-MMM-yyyy h:mm a'),
                'LineItems': JSON.stringify(invoice.lineItems.elements),
            };
        }
        return {
            'Id': '',
            'Note': '',
            'Email': '',
            'IsGuest': '',
            'Status': '',
            'Amount': '',
            'Created Time': '',
            'LineItems': '',
        };
    });

    const option = {
        fileName: 'reports-cloverorder',
        datas: [
            {
                sheetData: formattedData,  // Assume `formattedData` is your dataset
                sheetName: 'Orders',
                sheetFilter: [
                    'Id',
                    'Note',
                    'Email',
                    'IsGuest',
                    'Status',
                    'Amount',
                    'Created Time',
                    'LineItems',
                ],
                sheetHeader: [
                    'Id',
                    'Note',
                    'Email',
                    'IsGuest',
                    'Status',
                    'Amount',
                    'Created Time',
                    'LineItems',
                ],
                columnWidths: tempColumn.map(col => parseInt(col.width, 10) / 20)  // Adjust if needed for your export
            },
        ],
    };


    if (exportSelectedOption === 'Excel') {
        const toExcel = new ExportJsonExcel(option);
        toExcel.saveExcel();
    } else if (exportSelectedOption === 'CSV') {
        exportToCSV(formattedData, 'reports-cloverorder');
    } else if (exportSelectedOption === 'PDF') {
        exportToPDF(dataArr, 'reports-cloverorder');
    } else if (exportSelectedOption === 'Print') {
        //printData(formattedData);
    }
}

export function exportToCSV(data, fileName) {
    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", `${fileName}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export function exportToPDF(data, fileName) {
    const doc = new jsPDF('landscape');  // Use landscape mode for more horizontal space
    const tableColumn = tempColumn;
    const tableRows = [];

    data.forEach(invoice => {
        const row = [
            invoice.id,
            invoice?.note,
            invoice?.user?.email || '',
            invoice.user.guest ? 'YES' : 'NO',
            invoice?.paymentState,
            convertStripeAmount(invoice.total).toFixed(2),
            formatDate(invoice.createdTime),
            JSON.stringify(invoice?.lineItems?.elements),
        ];
        tableRows.push(row);
    });

    doc.autoTable({
        head: [tableColumn],
        body: tableRows,
        startY: 1.5,  // Adjust as needed
        styles: {
            cellPadding: 2,
            fontSize: 6,
            overflow: 'linebreak',
        },
        columnStyles: {
            0: { cellWidth: 30 },
            1: { cellWidth: 22 },
            2: { cellWidth: 32 },
            3: { cellWidth: 22 },
            4: { cellWidth: 22 },
            5: { cellWidth: 22 },
            6: { cellWidth: 36 },
        },
        theme: 'striped',  // Optional: adds a striped effect to the rows
        headStyles: { fillColor: [22, 160, 133] },  // Optional: change header color
    });

    doc.save(`${fileName}.pdf`);
}