import {
    Label,
    Table,
    TableBody,
    TableCell,
    TableCellLayout,
    TableHeader,
    TableHeaderCell,
    TableRow,
    makeStyles,
    shorthands,
    Spinner // Import Spinner for loading indication
} from "@fluentui/react-components";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAuthState } from 'react-firebase-hooks/auth';
import Layout from "./components/Layout";
import { Base_Url, auth } from "./services/firebase";

// Update columns to match the charges API response
const columns = [
    { columnKey: "email", label: "Email", width: '20%' },
    { columnKey: "customerCreated", label: "Customer Created", width: '18%' },
    { columnKey: "lastBillingCalculationDate", label: "Last Billing", width: '18%' },
    { columnKey: "needsToBeCharged", label: "Needs to Be Charged", width: '10%' },
    { columnKey: "message", label: "Message", width: '20%' },
    { columnKey: "hasCardBank", label: "Has Card/Bank", width: '10%' },
];

function SixMonthBillingCalculation() {
    const styles = useStyles();
    const [allCharges, setAllCharges] = useState([]);
    const [filteredCharges, setFilteredCharges] = useState([]);
    const [user, loading, error] = useAuthState(auth);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (user) {
            fetchCharges();
        }
    }, [user]);

    const fetchCharges = () => {
        const config = {
            method: 'get',
            url: `${Base_Url}/getBillingCalculationAPI`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': `Bearer ${user.accessToken}`,
                'Accept': "application/json"
            },
            params: { isFilter: true },
            timeout: 1000000
        };
        
        setIsLoading(true); // Set loading state to true
        axios.request(config)
            .then((response) => {
                console.log("response->", response);
                if (response.status === 200) {
                    const charges = response.data; // Ensure you're using response data
                    setAllCharges(charges);
                    setFilteredCharges(charges); // Set filtered charges to the same initially
                } else {
                    console.error(response.data.message || 'Failed to fetch charges.');
                }
            })
            .catch((err) => {
                console.log("response->", JSON.stringify(err));
                alert("An error occurred while fetching charge data.");
            })
            .finally(() => {
                setIsLoading(false); // Set loading state to false in finally block
            });
    }

    return (
        <Layout>
            <div className={styles.content}>
                <div>
                    {isLoading ? ( // Render loading spinner or message when loading
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <Spinner size="large" />
                        </div>
                    ) : (
                        <Table aria-label="Table with charge details">
                            <TableHeader className={"list-row"}>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableHeaderCell style={{ width: column.width }} key={column.columnKey}>
                                            <span className={"listHeaderText"}>{column.label}</span>
                                        </TableHeaderCell>
                                    ))}
                                </TableRow>
                            </TableHeader>
                            {filteredCharges.length > 0 ? (
                                <TableBody>
                                    {filteredCharges.map((charge, index) => (
                                        <TableRow key={index}>
                                            <TableCell>
                                                <TableCellLayout>{charge.email}</TableCellLayout>
                                            </TableCell>
                                            <TableCell>
                                                <TableCellLayout>{charge.customerCreated}</TableCellLayout>
                                            </TableCell>
                                            <TableCell>
                                                <TableCellLayout>{charge.lastBillingCalculationDate}</TableCellLayout>
                                            </TableCell>
                                            <TableCell>
                                                <TableCellLayout>{charge.needsToBeCharged ? charge.needsToBeCharged : '0'}</TableCellLayout>
                                            </TableCell>
                                            <TableCell>
                                                <TableCellLayout>{charge.message}</TableCellLayout>
                                            </TableCell>
                                            <TableCell>
                                                <TableCellLayout>{charge.hasPaymentMethods ? 'YES' : 'NO'}</TableCellLayout>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            ) : (
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={columns.length}>
                                            <Label>No data available</Label>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default SixMonthBillingCalculation;

const useStyles = makeStyles({
    root: {
        ...shorthands.overflow("hidden"),
        display: "flex",
        height: "480px",
        backgroundColor: "#fff"
    },

    content: {
        ...shorthands.flex(1),
        ...shorthands.padding("16px"),
        display: "block",
        ...shorthands.gridArea("main")
    },
});
