import {
    Button,
    Label,
    Spinner,
    Table, TableBody,
    TableCell,
    TableCellLayout,
    TableHeader,
    TableHeaderCell,
    TableRow,
    makeStyles, shorthands,
} from "@fluentui/react-components";
import { DatePicker } from "@fluentui/react-datepicker-compat";

import { DeleteFilled, DeleteRegular, EditFilled, EditRegular, bundleIcon } from "@fluentui/react-icons";
import { Tag, TagGroup } from "@fluentui/react-tags";
import { collection, getDocs, query, where } from "firebase/firestore";
import 'jspdf-autotable';
import React, { useEffect, useRef, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import Layout from "./components/Layout";
import { auth, db, trackException } from "./services/firebase";
//import ImageDialog from "./components/ImageDialog";
import debounce from 'lodash/debounce';
import './ProductOrderHistory.css';
import CustomSearchInput from "./components/CustomSearchInput";
import CustomSearchInput2 from "./components/CustomSearchInput2";
import { checkIfFromCardReaderPos, generateExelData, getChargeCardAmount, getGratuity, getMinimumspend, getPointspend, getSaleTax, getTotal } from "./components/OrderHistoryUtil";
import orderHistoryDataService from "./services/orderHistoryDataService";
import userDataService from "./services/userDataService";
import { CalanderSVG, DownArowSVG, DownSmallArrow, DownloadSVG, FilterSVG, PrintIconSVG, RadioSelected, RadioUnSelected } from "./static/svgs";
import { BookingCategory } from "./Helper";
const EditIcon = bundleIcon(EditFilled, EditRegular);
const DeleteIcon = bundleIcon(DeleteFilled, DeleteRegular);

const useStyles = makeStyles({
    root: {
        ...shorthands.overflow("hidden"),
        display: "flex",
        height: "480px",
        backgroundColor: "#fff"
    },

    content: {
        ...shorthands.flex(1),
        ...shorthands.padding("16px"),
        display: "block",
        ...shorthands.gridArea("main")
    },

    breakFlex: {
        flexBasis: "100%",
        textAlign: "left"
    }
});

const columns = [
    { columnKey: "id", label: "Id", width: '16%' },
    { columnKey: "orderDate", label: "Order Date", width: '15%' },
    { columnKey: "orderBy", label: "Order By", width: '14%' },
    { columnKey: "product", label: "Product", width: '24%' },
    { columnKey: "pay", label: "Pay", width: '16%' },
    { columnKey: "total", label: "Total", width: '10%' },
];

const comparisonDate = new Date('2024-08-20T00:00:00Z');

function ProductOrderHistory() {
    const styles = useStyles();
    const [user, loading, error] = useAuthState(auth);
    const [allOrderHistory, setAllOrderHistory] = useState([]);

    const [searchTerm, setSearchTerm] = useState('');
    const [filteredOrderHistory, setFilteredOrderHistory] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(40); // Number of events per page
    const [startAfterDoc, setStartAfterDoc] = useState(null); // Track the last document of the previous page
    const [isLoading, setIsLoading] = useState(false);
    const [hasMoreData, setHasMoreData] = useState(true);
    const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
    const [popupVisible, setPopupVisible] = useState(false);
    const [popupVisibleDate, setPopupVisibleDate] = useState(false);
    const [popupVisibleExport, setPopupVisibleExport] = useState(false);
    const [members, setMembers] = useState([
        { id: 1, name: 'John', selected: false },
        { id: 2, name: 'Jane', selected: false },
        { id: 3, name: 'Doe', selected: false }
    ]);
    const [searchMembers, setSearchMembers] = useState('');
    const [visibleTags, setVisibleTags] = useState([]);
    const firstTagRef = useRef(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedStartDate, setSelectedStartDate] = useState(null);
    const [selectedEndDate, setSelectedEndDate] = useState(null);
    const [exportSelectedOption, setExportSelectedOption] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/");
        setIsLoading(true)
        fetchUserList()
        fetchProductOrderHistory();
    }, [user, loading]);

    useEffect(() => {
        if (visibleTags && visibleTags.length > 0) {
            const selectedMembers = members.filter(member => member.selected);
            const selectedMemberEmails = selectedMembers.map(member => member.email);
            if (selectedOption) {
                fetchFilterProductOrderHistory(selectedOption)
            } else {
                fetchProductOrderHistoryByUser(selectedMemberEmails)
            }
        } else {
            fetchProductOrderHistory()
        }
    }, [visibleTags]);

    useEffect(() => {
        // Fetch events when the page number changes
        if (page > 1) {
            loadMoreEvents();
        }
    }, [page]);

    const fetchProductOrderHistory = async () => {
        setPage(1)
        setStartAfterDoc(null)
        setHasMoreData(true)
        setAllOrderHistory([])
        try {
            if (!!user) {
                const userquery = query(collection(db, "Users"), where("email", "==", user?.email));
                const userdoc = await getDocs(userquery);
                if (userdoc.docs.length > 0) {
                    const { orderHistory, lastVisible } = await orderHistoryDataService.getAllOrderHistoryWithPage(pageSize, null);
                    // Update the startAfterDoc for the next page
                    setStartAfterDoc(lastVisible);
                    let allEventArr = orderHistory
                    if (allEventArr.length < pageSize) {
                        setHasMoreData(false)
                    }
                    setAllOrderHistory(allEventArr);
                    setFilteredOrderHistory(allEventArr)
                } else {
                    console.log('no access');
                }
                setIsLoading(false)
            }
        } catch (err) {
            trackException(JSON.stringify(err))
            setIsLoading(false)
            console.error(err);
            alert("An error occured while fetching user data");
        }
    };

    const fetchProductOrderHistoryByUser = async (emailIds) => {
        setPage(1)
        setStartAfterDoc(null)
        setHasMoreData(false)
        try {
            if (!!user) {
                const { orderHistory, lastVisible } = await orderHistoryDataService.getOrderHistoryByUserIdWithPage(emailIds, null, null);
                let allEventArr = orderHistory
                if (allEventArr.length < pageSize) {
                    setHasMoreData(false)
                }
                setFilteredOrderHistory(allEventArr)
            }
        } catch (err) {
            trackException(JSON.stringify(err))
            setIsLoading(false)
            console.error(err);
            alert("An error occured while fetching user data");
        }
    };

    const fetchFilterProductOrderHistory = async (selection) => {
        setPage(1)
        setStartAfterDoc(null)
        setHasMoreData(true)
        try {
            if (!!user) {
                let selectedMemberEmails = []
                if (visibleTags && visibleTags.length > 0) {
                    const selectedMembers = members.filter(member => member.selected);
                    selectedMemberEmails = selectedMembers.map(member => member.email);
                }
                const { orderHistory, lastVisible } = await orderHistoryDataService.getAllOrderHistoryBySelection(selection, selectedStartDate, selectedEndDate, selectedMemberEmails)
                let allEventArr = orderHistory
                setFilteredOrderHistory(allEventArr)
            }
        } catch (err) {
            trackException(JSON.stringify(err))
            setIsLoading(false)
            console.error(err);
            alert("An error occured while fetching user data");
        }
    };

    const loadMoreEvents = async () => {
        const { orderHistory, lastVisible } = await orderHistoryDataService.getAllOrderHistoryWithPage(pageSize, startAfterDoc);
        // Update the startAfterDoc for the next page
        setStartAfterDoc(lastVisible);
        if (orderHistory.length < pageSize) {
            setHasMoreData(false)
            // You can handle this case, e.g., display a message
        } else {
            if (allOrderHistory && allOrderHistory.length > 0) {
                setAllOrderHistory([...allOrderHistory, ...orderHistory]);
                setFilteredOrderHistory([...allOrderHistory, ...orderHistory]);
            } else {
                setAllOrderHistory(orderHistory);
                setFilteredOrderHistory(orderHistory);
            }
        }
    };

    const fetchUserList = async () => {
        try {
            if (!!user) {
                const usersQuery = await userDataService.getAllUser();
                let allUserList = usersQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
                setAllUsers(allUserList);
                const transformedArray = allUserList.map(obj => ({
                    id: obj.id,
                    name: `${obj.firstName} ${obj.lastName}`,
                    email: obj.email,
                    selected: false
                }));
                setMembers(transformedArray)
            }
        } catch (err) {
            console.error(err);
        }
    };

    // Debounce the handleSearch function
    const debouncedSearch = debounce(search => {
        const lowerSearch = search.toLowerCase();
        const filtered = allOrderHistory.filter(event =>
            event.id.toLowerCase().includes(lowerSearch)
        );
        setFilteredOrderHistory([]);
        setFilteredOrderHistory(filtered);
    }, 300); // Adjust the debounce delay as needed

    const handleSearch = event => {
        const value = event.target.value;
        setSearchTerm(value);
        debouncedSearch(value);
    };
    const handleClear = () => {
        setSearchTerm('');
        debouncedSearch('');
    };

    const handleSearchMember = event => {
        const value = event.target.value;
        setSearchMembers(value);
    };

    const handleOptionClick = (option) => {
        setSelectedOption(prevOption => prevOption === option ? null : option);
    };

    const handleLoadMore = () => {
        setPage(prevPage => prevPage + 1);
    };

    const removeItem = (e, { value }) => {
        setVisibleTags([...visibleTags].filter((tag) => tag.value !== value));
        setMembers(prevMembers =>
            prevMembers.map(member =>
                member.id === value ? { ...member, selected: false } : member
            )
        );
        // Reapply filtering to update filtered order history
        // const remainingTags = visibleTags.filter(tag => tag.value !== value);
        // if (remainingTags.length === 0) {
        //     setFilteredOrderHistory(allOrderHistory); // Show all order history if no tag is left
        // } else {
        //     const selectedMemberEmails = remainingTags.map(tag => members.find(member => member.id === tag.value).email);
        //     const filteredOrders = allOrderHistory.filter(order => selectedMemberEmails.includes(order.email));
        //     setFilteredOrderHistory(filteredOrders);
        // }
    };

    const openPopup = (event) => {
        // Calculate the position of the button
        const buttonRect = event.target.getBoundingClientRect();
        const buttonX = buttonRect.left + (buttonRect.width / 2);
        const buttonY = buttonRect.top + (buttonRect.height / 2);

        // Set the position of the popup relative to the button
        const popupWidth = 315;
        const popupHeight = 356;
        setPopupPosition({ x: buttonX, y: buttonY + 10 }); // Adjust 10px downward for better alignment

        // Show the popup
        setPopupVisible(true);
        setPopupVisibleDate(false);
        setPopupVisibleExport(false)
    };

    const openDatePopup = (event) => {
        // Calculate the position of the button
        const buttonRect = event.target.getBoundingClientRect();
        const buttonX = buttonRect.left + (buttonRect.width / 2);
        const buttonY = buttonRect.top + (buttonRect.height / 2);

        // Set the position of the popup relative to the button
        const popupWidth = 315;
        const popupHeight = 356;
        setPopupPosition({ x: buttonX, y: buttonY + 10 }); // Adjust 10px downward for better alignment

        // Show the popup
        setPopupVisible(false);
        setPopupVisibleExport(false)
        setPopupVisibleDate(true);
    };
    const openExportPopup = (event) => {
        // Calculate the position of the button
        const buttonRect = event.target.getBoundingClientRect();
        const buttonX = buttonRect.left - 100 //+ (buttonRect.width / 2);
        const buttonY = buttonRect.top + (buttonRect.height / 2);
        setPopupPosition({ x: buttonX, y: buttonY + 10 }); // Adjust 10px downward for better alignment
        // Show the popup
        setPopupVisible(false);
        setPopupVisibleDate(false);
        setPopupVisibleExport(true)
    };

    // Function to handle checkbox toggle
    const toggleSelect = (id) => {
        setMembers((prevMembers) =>
            prevMembers.map((member) =>
                member.id === id ? { ...member, selected: !member.selected } : member
            )
        );
    };

    const handleApplyMember = () => {
        const selectedMembers = members.filter(member => member.selected);
        // Update visible tags
        const updatedTags = selectedMembers.map(member => ({
            value: member.id,
            children: member.name
        }));
        setVisibleTags(updatedTags);
        // Close the popup
        setPopupVisible(false);
    };

    const handleCancelMember = () => {
        setPopupVisible(false);
        setPopupVisibleDate(false);
    };

    const handleCancelDateFilter = () => {
        // Close the popup
        setSelectedOption(null)
        setPopupVisible(false);
        setPopupVisibleDate(false);
        fetchProductOrderHistory();
    };

    const handleCancelExport = () => {
        // Close the popup
        setPopupVisibleExport(false)
    };

    const handleDateApply = () => {
        fetchFilterProductOrderHistory(selectedOption)
        setPopupVisible(false);
        setPopupVisibleDate(false);
    }

    const handleDateSelect = (date) => {
        setSelectedOption("CustomDate")
        setSelectedStartDate(date);
    };
    const handleDateSelect2 = (date) => {
        setSelectedOption("CustomDate")
        setSelectedEndDate(date);
    };

    const handleExportChange = (option) => {
        setExportSelectedOption(option);
    };

    function onGenerateExportClick(from) {
        const dataArr = filteredOrderHistory;
        if (from == 2) {
            generateExelData(dataArr, 'Print', allUsers)
        } else {
            generateExelData(dataArr, exportSelectedOption, allUsers)
            setPopupVisibleExport(false)
        }
    }

    function renderPosItem(item) {
        const productInfo = JSON.parse(item.metadata.productInfo);
        const intentCreatedAt = new Date(item.intentCreatedAt * 1000);
        return (
            <TableRow key={item.id}>
                <TableCell className={"table-row-fonts__primary"}>
                    <TableCellLayout>
                        {item.id}
                    </TableCellLayout>
                </TableCell>
                <TableCell className={"table-row-fonts__desc"}>
                    <TableCellLayout>
                        {intentCreatedAt.toDateString()}
                    </TableCellLayout>
                </TableCell>
                <TableCell className={"table-row-fonts__desc"}>
                    <TableCellLayout>
                        {/* <Label title={item.email}>{item.email}</Label> */}
                        {
                            item.guestName && item.guestName != "" ?
                                <Label>{item.guestName}(Guest)</Label> :
                                <Label>{allUsers.filter(t => t.email == item.email)[0]?.firstName} &nbsp;
                                    {allUsers.filter(t => t.email == item.email)[0]?.lastName}</Label>
                        }
                    </TableCellLayout>
                </TableCell>
                <TableCell className={"table-row-fonts__desc"}>
                    <TableCellLayout>
                        <ProductList products={item?.products} productInfo={productInfo} />
                    </TableCellLayout>
                </TableCell>
                <TableCell className={"table-row-fonts__desc"}>
                    <TableCellLayout>
                        <div style={{}}>
                            <div style={styles.productName}>Gratuity: ${getGratuity(item)}</div>
                            <div style={styles.productName}>SaleTax: ${getSaleTax(item)}</div>
                            {
                                checkIfFromCardReaderPos(item) == true ?
                                    <div style={styles.productName}>Card Reader POS</div> :
                                    getChargeCardAmount(item) > 0 ?
                                        <div style={styles.productName}>Credit Card: ${getChargeCardAmount(item)}</div> :
                                        getMinimumspend(item) > 0 ?
                                            <div style={styles.productName}>Use Minimum: ${getMinimumspend(item)}</div> : <div />
                            }
                        </div>
                    </TableCellLayout>
                </TableCell>
                <TableCell className={"table-row-fonts__desc"}>
                    <TableCellLayout>
                        {
                            intentCreatedAt < comparisonDate ?
                                <div>${(item.amount / 100).toFixed(2)}</div> :
                                <div>${getTotal(item)}</div>
                        }
                    </TableCellLayout>
                </TableCell>
            </TableRow>
        )
    }

    function renderEventsItem(item) {
        const enventData = JSON.parse(item?.metadata?.eventInfo);
        const intentCreatedAt = new Date(item.intentCreatedAt * 1000);
        return (
            <TableRow key={item.id}>
                <TableCell className={"table-row-fonts__primary"}>
                    <TableCellLayout>
                        {item.id}
                    </TableCellLayout>
                </TableCell>
                <TableCell className={"table-row-fonts__desc"}>
                    <TableCellLayout>
                        {intentCreatedAt.toDateString()}
                    </TableCellLayout>
                </TableCell>
                <TableCell className={"table-row-fonts__desc"}>
                    <TableCellLayout>
                        <Label>{allUsers.filter(t => t.email == item.email)[0]?.firstName} &nbsp;
                            {allUsers.filter(t => t.email == item.email)[0]?.lastName}</Label>
                    </TableCellLayout>
                </TableCell>
                <TableCell className={"table-row-fonts__desc"}>
                    <TableCellLayout>
                        <div style={styles.productName}>{BookingCategory.Paid_Event_RSVP}</div>
                        <EventInfo  eventInfo={enventData} />
                    </TableCellLayout>
                </TableCell>
                <TableCell className={"table-row-fonts__desc"}>
                    <TableCellLayout>
                        <div style={{}}>
                            {
                                getChargeCardAmount(item) > 0 ?
                                    <div style={styles.productName}>Credit Card ${(item.amount / 100).toFixed(2)}</div> :
                                    getMinimumspend(item) > 0 ?
                                        <div style={styles.productName}>Use Minimum ${getMinimumspend(item)}</div> : <div />
                            }
                        </div>
                    </TableCellLayout>
                </TableCell>
                <TableCell className={"table-row-fonts__desc"}>
                    <TableCellLayout>
                        {
                            <div>${(item.amount / 100).toFixed(2)}</div>
                        }
                    </TableCellLayout>
                </TableCell>
            </TableRow>
        )
    }

    function renderRentalItem(item) {
        const intentCreatedAt = new Date(item.intentCreatedAt * 1000);
        return (
            <TableRow key={item.id}>
                <TableCell className={"table-row-fonts__primary"}>
                    <TableCellLayout>
                        {item.id}
                    </TableCellLayout>
                </TableCell>
                <TableCell className={"table-row-fonts__desc"}>
                    <TableCellLayout>
                        {intentCreatedAt.toDateString()}
                    </TableCellLayout>
                </TableCell>
                <TableCell className={"table-row-fonts__desc"}>
                    <TableCellLayout>
                        <Label>{allUsers.filter(t => t.email == item.email)[0]?.firstName} &nbsp;
                            {allUsers.filter(t => t.email == item.email)[0]?.lastName}</Label>
                    </TableCellLayout>
                </TableCell>
                <TableCell className={"table-row-fonts__desc"}>
                    <TableCellLayout>
                        <div style={styles.productName}>{BookingCategory.Vehicle_Booking}</div>
                    </TableCellLayout>
                </TableCell>
                <TableCell className={"table-row-fonts__desc"}>
                    <TableCellLayout>
                        <div style={{}}>
                            {
                                getChargeCardAmount(item) > 0 ?
                                    <div style={styles.productName}>Credit Card: ${getChargeCardAmount(item)}</div>
                                    : <div />
                            }
                            {
                                getMinimumspend(item) > 0 ?
                                    <div style={styles.productName}>Minimum: ${getMinimumspend(item)}</div>
                                    : <div />
                            }
                            {
                                getPointspend(item) > 0 ?
                                    <div style={styles.productName}>Driving Fleet: ${getPointspend(item)}</div>
                                    : <div />
                            }
                        </div>
                    </TableCellLayout>
                </TableCell>
                <TableCell className={"table-row-fonts__desc"}>
                    <TableCellLayout>
                        {
                            <div>${(item.amount / 100).toFixed(2)}</div>
                        }
                    </TableCellLayout>
                </TableCell>
            </TableRow>
        )
    }
    return (
        <Layout>
            <div className={styles.content}>
                <div className={"d-flex justify-space-between"}>
                    <div className={"d-flex"}>
                        <div>
                            <FilterSVG />
                        </div>
                        <div className={'memberNameDiv'} onClick={openPopup}>
                            <span>MEMBER NAME</span>
                            <DownArowSVG />
                        </div>
                        <div className={'memberNameDiv'} onClick={openDatePopup}>
                            <span>Order Date</span>
                            <DownArowSVG />
                        </div>
                    </div>
                    <CustomSearchInput
                        value={searchTerm}
                        onChange={handleSearch}
                        onClick={handleClear}
                    />
                    <div className={"d-flex"}>
                        <div className={"exportbtn"} onClick={openExportPopup}>
                            <DownloadSVG />
                            <span>Export</span>
                            <DownSmallArrow />
                        </div>
                        <div className={"printbtn"} onClick={() => { onGenerateExportClick(2) }}>
                            <PrintIconSVG />
                            <span>Print</span>
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: '10px' }}>
                    {visibleTags.length !== 0 && (
                        <TagGroup onDismiss={removeItem} aria-label="Dismiss example">
                            {visibleTags.map((tag, index) => (
                                <Tag
                                    dismissible
                                    dismissIcon={{ "aria-label": "remove" }}
                                    value={tag.value}
                                    key={tag.value}
                                    ref={index === 0 ? firstTagRef : null}
                                >
                                    {tag.children}
                                </Tag>
                            ))}
                        </TagGroup>
                    )}
                </div>

                <div className={"table-layout"}>
                    <Table aria-label="Table with cell actions">
                        <TableHeader className={"list-row"}>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableHeaderCell style={{ width: column.width }} key={column.columnKey}>
                                        <span className={"listHeaderText"}>{column.label}</span>
                                    </TableHeaderCell>
                                ))}
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {filteredOrderHistory.length > 0 && filteredOrderHistory.map((item) => {
                                if (item.metadata.productInfo) {
                                    return renderPosItem(item)
                                } 
                                else if (item.metadata && item.metadata.isPaymentFrom && item.metadata.isPaymentFrom == BookingCategory.Paid_Event_RSVP) {
                                    return renderEventsItem(item)
                                } else if (item.metadata && item.metadata.isPaymentFrom && item.metadata.isPaymentFrom == BookingCategory.Vehicle_Booking) {
                                    return renderRentalItem(item)
                                }
                                else {
                                    return (<div></div>)
                                }
                            })}
                        </TableBody>
                    </Table>
                </div>
                <div style={{ marginTop: '10px', textAlign: 'center' }}>
                    {isLoading && <Spinner />} {/* Render loader if loading state is true */}
                    {!isLoading && hasMoreData && searchTerm == "" && visibleTags.length < 1 && selectedOption == null && filteredOrderHistory.length > 0 && <Button onClick={handleLoadMore}>Load More...</Button>} {/* Render button if not loading */}
                </div>
                {popupVisible && (
                    <div className="popupmain" style={{ position: 'absolute', top: popupPosition.y, left: popupPosition.x }}>
                        <CustomSearchInput2
                            value={searchMembers}
                            onChange={handleSearchMember}
                            onClick={handleClear}
                        />
                        <div className="popupMemberList">
                            {members
                                .filter(member => member.name.toLowerCase().includes(searchMembers))
                                .map((member) => (
                                    <div key={member.id}>
                                        <Label className="checkLabeldiv">
                                            <input
                                                type="checkbox"
                                                checked={member.selected}
                                                onChange={() => toggleSelect(member.id)}
                                            />
                                            {member.name}
                                        </Label>
                                    </div>
                                ))}
                        </div>
                        <div className="buttonContainer">
                            <button className="borderButton" onClick={handleCancelMember}>Cancel</button>
                            <button className="fillButton" onClick={handleApplyMember}>APPLY</button>
                        </div>
                    </div>
                )}
                {popupVisibleExport && (
                    <div className="popupexport" style={{ position: 'absolute', top: popupPosition.y, right: 40 }}>
                        <div>
                            <div className="radioFrame" onClick={() => handleExportChange('CSV')}>
                                {exportSelectedOption === 'CSV' ? <RadioSelected /> : <RadioUnSelected />}
                                <div className="radioBtnText">CSV</div>
                            </div>
                            <div className="radioFrame" onClick={() => handleExportChange('PDF')}>
                                {exportSelectedOption === 'PDF' ? <RadioSelected /> : <RadioUnSelected />}
                                <div className="radioBtnText">PDF</div>
                            </div>
                            <div className="radioFrame" onClick={() => handleExportChange('Excel')}>
                                {exportSelectedOption === 'Excel' ? <RadioSelected /> : <RadioUnSelected />}
                                <div className="radioBtnText">Excel</div>
                            </div>
                        </div>
                        <div className="buttonContainer">
                            <button className="borderButton" onClick={handleCancelExport}>Cancel</button>
                            <button className="fillButton" onClick={() => { onGenerateExportClick(1) }}>EXPORT</button>
                        </div>
                    </div>
                )}

                {popupVisibleDate && (
                    <div className="popupmain" style={{ position: 'absolute', top: popupPosition.y, left: popupPosition.x }}>
                        <div className="dateFramesContainer">
                            <div style={{ width: '48%' }}>
                                <span>Start Date</span>
                                <DatePicker
                                    showTimeSelect
                                    selected={selectedStartDate}
                                    contentAfter={<CalanderSVG />}
                                    onSelectDate={(e) => handleDateSelect(e)}
                                    style={{
                                        width: "100%",
                                        height: "44px"
                                    }}
                                    placeholder="Start Date"
                                />
                            </div>
                            <div className="dashLine"></div> {/* Add a separate element for the dash line */}
                            <div style={{ width: '48%' }}>
                                <span>End Date</span>
                                <DatePicker
                                    showTimeSelect
                                    selected={selectedEndDate}
                                    contentAfter={<CalanderSVG />}
                                    onSelectDate={(e) => handleDateSelect2(e)}
                                    style={{
                                        width: "100%",
                                        height: "44px"
                                    }}
                                    placeholder="End Date"
                                />
                            </div>
                        </div>

                        <div style={{ marginTop: '10px' }} className="seperateLine"></div>

                        {["Today", "Yesterday", "This month", "Past month"].map(option => (
                            <div
                                key={option}
                                className={`todayBtn${selectedOption === option ? " selected" : ""}`}
                                onClick={() => handleOptionClick(option)}
                            >
                                {option}
                            </div>
                        ))}
                        <div className="seperateLine"></div>
                        <div className="buttonContainer">
                            <button className="borderButton" onClick={handleCancelDateFilter}>Cancel</button>
                            <button className="fillButton" onClick={handleDateApply}>APPLY</button>
                        </div>
                    </div>
                )}
            </div>
        </Layout>
    )
}

export default ProductOrderHistory;

const ProductList = ({ productInfo, products }) => {
    const calculateTotal = (items) => {
        return items.reduce((total, product) => {
            return total + (product.price * product.quantity);
        }, 0);
    };

    const totalAmount = products && products.length > 0
        ? calculateTotal(products)
        : calculateTotal(productInfo);

    if (products && products.length > 0) {
        return (
            <div>
                {products.map((product, index) => (
                    <div key={index}>
                        <div key={index} style={styles.productDetailsContainer}>
                            <div className="d-flex" style={{}}>
                                <div style={styles.productName}>{product.quantity} x</div>
                                <div style={styles.productName2} >{`${product.productName}`}/</div>
                                <div style={styles.productName}>${(product.price / 100).toFixed(2)}</div>
                            </div>

                        </div>
                    </div>
                ))}
                <div style={styles.productTotal}>Product Total: ${(totalAmount / 100).toFixed(2)}</div>
            </div>
        );
    } else {
        return (
            <div>
                {productInfo.map((product, index) => (
                    <div key={index}>
                        <div key={index} style={styles.productDetailsContainer}>
                            <div className="d-flex" style={{}}>
                                <div style={styles.productName}>{product.quantity} x</div>
                                <div style={styles.productName2} >{`${product.name}`}/</div>
                                <div style={styles.productName}>${(product.price / 100).toFixed(2)}</div>
                            </div>
                        </div>
                    </div>
                ))}
                <div style={styles.productTotal}>Total: ${(totalAmount / 100).toFixed(2)}</div>
            </div>
        );
    }
};


const EventInfo = ({ eventInfo }) => {
    if (eventInfo && eventInfo.length > 0) {
        return (
            <div>
                {eventInfo.map((product, index) => (
                    <div key={index}>
                        <div key={index} style={styles.productDetailsContainer}>
                            <div className="d-flex" style={{}}>
                                <div style={styles.productName}>{product.name}</div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        );
    } else {
        return (
            <div>
            </div>
        );
    }
};

const styles = {
    productDetailsContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    imageContainer: {
        marginRight: 10,
    },
    productImage: {
        width: 62,
        height: 62,
        borderRadius: 5,
    },
    productName: {
        color: '#202020',
        fontFamily: 'Nunito Sans',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px'
    },
    productName2: {
        marginLeft: '6px',
        color: '#202020',
        fontFamily: 'Nunito Sans',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px'
    },
    productTotal: {
        marginTop: '2px',
        color: '#202020',
        fontFamily: 'Nunito Sans',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '20px',
    },
    productPrice: {
        fontSize: 12,
        color: '#535353'
    },
    separator: {
        width: '100%',
        borderTop: '1px solid #ccc',
        marginTop: 10,
        marginBottom: 10
    }
};