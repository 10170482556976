import { addDoc, collection, deleteDoc, doc, getDoc, updateDoc, getDocs, orderBy, startAfter, query, limit } from "firebase/firestore";
import { db } from "./firebase";

const roomsCollection = collection(db, "Rooms");
const closureHoursCollection = collection(db, "ClosureHours");
const operatingHoursCollection = collection(db, "OperatingHours");

class RoomsDataService {
    addRooms = (newRooms) => {
        return addDoc(roomsCollection, newRooms);
    }

    updateRooms = (id, updatedRooms) => {
        const roomsDoc = doc(db, "Rooms", id);
        return updateDoc(roomsDoc, updatedRooms);
    }

    deleteRooms = (id) => {
        const roomsDoc = doc(db, "Rooms", id);
        return deleteDoc(roomsDoc);
    }

    getAllRooms = () => {
        return getDocs(roomsCollection)
    }

    getRooms = (id) => {
        const roomsDoc = doc(db, "Rooms", id);
        return getDoc(roomsDoc);
    }
    addClosureHours = (newRooms) => {
        return addDoc(closureHoursCollection, newRooms);
    }
    updateClosureHours = (id, updatedRooms) => {
        const roomsDoc = doc(db, "ClosureHours", id);
        return updateDoc(roomsDoc, updatedRooms);
    }
    getClosureHours = () => {
        return getDocs(closureHoursCollection)
    }
    deleteClosureHours = (id) => {
        const roomsDoc = doc(db, "ClosureHours", id);
        return deleteDoc(roomsDoc);
    }
    addOperatingHours = (newRooms) => {
        return addDoc(operatingHoursCollection, newRooms);
    }
    updateOperatingHours = (id, updatedRooms) => {
        const roomsDoc = doc(db, "OperatingHours", id);
        return updateDoc(roomsDoc, updatedRooms);
    }
    getOperatingHours = () => {
        return getDocs(operatingHoursCollection)
    }
    deleteOperatingHours = (id) => {
        const roomsDoc = doc(db, "OperatingHours", id);
        return deleteDoc(roomsDoc);
    }
}

export default new RoomsDataService();