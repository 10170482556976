import {
  Button,
  Dropdown,
  Label,
  Option,
  Switch,
  makeStyles,
  shorthands
} from "@fluentui/react-components";
import {
  DrawerBody,
  DrawerHeader,
  DrawerHeaderTitle,
  DrawerOverlay,
} from "@fluentui/react-components/unstable";
import { Dismiss24Regular } from "@fluentui/react-icons";
import moment from "moment";
import { Timestamp } from "firebase/firestore";
import { useEffect, useState } from "react";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import "react-datepicker/dist/react-datepicker.css";
import { Controller, useForm } from "react-hook-form";
import VehiclesRequestDataService from "../../services/vehiclesRequestDataService";
import NotificationDataService from "../../services/notificationDataService";
import { AddressSVG, DateSVG } from "../../static/svgs";
import { getTimeFromDate, mergeDateTime } from "../../Helper";

export const InitialVehiclesRequestState = {
  id: "",
  vehicleId: "",
  vehicle: {},
  memberId: "",
  isNotification: false,
  pickUpDate: null
};

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    // Use 2px gap below the label (per the design system)
    ...shorthands.gap("2px"),
  },
  control: {
    maxWidth: "300px",
  },
});

function VehiclesRequestForm(props) {
  const styles = useStyles();
  const [placeholderV, setPlaceholderV] = useState("");
  const [selectedMemberPlaceholder, setSelectedMemberPlaceholder] = useState('');

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { isDirty, dirtyFields },
  } = useForm({
    defaultValues: props.selectedVehicleReq && typeof props.selectedVehicleReq == "object"
      ? props.selectedVehicleReq
      : InitialVehiclesRequestState,
  });

  const onSubmit = async (formdata) => {
    updateDataInDb(formdata);
  };

  async function updateDataInDb(formdata) {
    var data = formdata;
    let selectedCar = props.vehicles.filter((obj) => obj.id == data.vehicleId);
    data.vehicle = selectedCar[0];
    const startTimestamp = Timestamp.fromDate(new Date());
    data.createdAt = startTimestamp;

    const mergedstartDateTime = mergeDateTime(data.startDate, data.startTime);
    const atimestamp = Timestamp.fromDate(new Date(mergedstartDateTime));
    data.pickUpDate = atimestamp

    if (props.selectedVehicleReq && typeof props.selectedVehicleReq == "object") {
      data.id = props.selectedVehicleReq.id;
      await VehiclesRequestDataService.updateVehiclesRequest(props.selectedVehicleReq.id, data);
    } else {
      const addeddata = await VehiclesRequestDataService.addVehiclesRequest(data);
      data.id = addeddata.id;
      await VehiclesRequestDataService.updateVehiclesRequest(data.id, data);
    }
    let id = data.id;
    sendEmailToMember(data)
    sendEmailToAdmin(data)
    props.setIsOpen(false);
    props.updateGrid();
  }

  async function sendEmailToMember(data) {
    let selectedMember = props.users.filter(obj => obj.id == data.memberId);
    if (data.isNotification && selectedMember[0] && selectedMember[0].email) {
      let msg = `We wanted to confirm that we've received your Vehicle Request. \nRequest Ref. ${data.id}`
      let message = {
        subject: `Vehicle Request`,
        html: msg,
      }
      let emailId = selectedMember[0].email
      let mailData = {
        to: emailId,
        message: message,
      }
      await NotificationDataService.addEmail(mailData)
    }
  }
  async function sendEmailToAdmin(data) {

    let selectedMember = props.users.filter(obj => obj.id == data.memberId);
    if (selectedMember) {
      try {
        let user = `${selectedMember[0]?.firstName} ${selectedMember[0]?.lastName}`
        let vData = data.vehicle
        const dateString = `${data.startDate}`.split(' ').slice(0, 4).join(' ');
        if (data.isNotification && selectedMember[0] && selectedMember[0].email) {
          let msg = `<p>${user} has requested a Vehicle. <br> Vehicle: ${vData?.year} ${vData?.make} ${vData?.model} <br>Request Ref. ${data.id} <br>Pickup Date: ${dateString} Time: ${data.startTime}</p>`
          let message = {
            subject: `Vehicle Request`,
            html: msg,
          }
          let mailData = {
            to: ['jake@warehousepc.com', 'nate@warehousepc.com', 'alexa@warehousepc.com', 'luke@warehousepc.com', 'jonas@warehousepc.com'],
            message: message,
          }
          // let mailData = {
          //   to: ['mj@acultivatedmindset.com', 'mitesh.jadav@byptopletechnologies.com'],
          //   message: message,
          // }
          await NotificationDataService.addEmail(mailData)
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  function onVehicleChange(vehicleId) {
    let selectedCar = props.vehicles.filter((obj) => obj.id == vehicleId);
    setValue("vehicle.warehouseBayNumber", selectedCar[0].warehouseBayNumber);
  }

  useEffect(() => {
    if (props.selectedVehicleReq && typeof props.selectedVehicleReq == "object") {
      const fields = ["id", "vehicleId", "memberId", "vehicle"];
      fields.forEach((field) =>
        setValue(
          field,
          props.selectedVehicleReq[field]
            ? props.selectedVehicleReq[field]
            : InitialVehiclesRequestState[field]
        )
      );
      setValue("vehicleId", props.selectedVehicleReq.vehicle?.id);
      let selectedMember = props.users.filter(obj => obj.id == props.selectedVehicleReq.memberId);
      if (selectedMember[0] && selectedMember[0].firstName) {
        let str = `${selectedMember[0].firstName} ${selectedMember[0].lastName}`
        setSelectedMemberPlaceholder(str);
      } else {
        setSelectedMemberPlaceholder('');
      }
      let selectedCar = props.vehicles.filter(
        (obj) => obj.id == props.selectedVehicleReq.vehicle?.id
      );
      if (selectedCar[0] && selectedCar[0].make) {
        let str = `${selectedCar[0].make} ${selectedCar[0].model}`;
        setPlaceholderV(str);
      } else {
        setPlaceholderV("");
      }
      if (props.selectedVehicleReq.pickUpDate) {
        setValue('startDate', props.selectedVehicleReq.pickUpDate.toDate());
        const startTime = getTimeFromDate(props.selectedVehicleReq.pickUpDate.toDate());
        setValue('startTime', startTime);
      }
    } else {
      const fields = ["id", "vehicleId", "memberId", "vehicle"];
      fields.forEach((field) =>
        setValue(field, InitialVehiclesRequestState[field])
      );
      setValue('startDate', '');
      setValue('startTime', '');
      setPlaceholderV("");
      setSelectedMemberPlaceholder('');
      setValue("vehicle.warehouseBayNumber", '');
    }
  }, [props.selectedVehicleReq]);

  return (
    <DrawerOverlay
      style={{ width: "504px" }}
      size={"medium"}
      position={"right"}
      open={props.isOpen}
      onOpenChange={(_, { open }) => props.setIsOpen(open)}
    >
      <DrawerHeader style={{
        background: "#F7F7F7",
        padding: "26px 32px"
      }}>
        <DrawerHeaderTitle
          action={
            <Button
              appearance="subtle"
              aria-label="Close"
              icon={<Dismiss24Regular />}
              onClick={() => props.setIsOpen(false)}
            />
          }
        >
          + {props.selectedVehicleReq && typeof props.selectedVehicleReq == "object" ? "Edit" : "New"} Vehicle Request
        </DrawerHeaderTitle>
      </DrawerHeader>
      <DrawerBody>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={styles.root}
          onReset={reset}
          id={props.selectedVehicleReq ? props.selectedVehicleReq.id : "New"}
        >
          <Label className={"side-drawer-field__label"} style={{ marginTop: "32px" }}>Vehicle*</Label>
          <Controller
            name="vehicleId"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Dropdown
                className={"side-drawer-field-dropdown  side-drawer-field__gap"}
                multiselect={false}
                onOptionSelect={(ev, data) => {
                  field.onChange(data.selectedOptions[0])
                  onVehicleChange(data.selectedOptions[0])
                }
                }
                selectedOptions={[field.value]}
                placeholder={!!placeholderV ? placeholderV : "Select Vehicle"}
              >
                {props.vehicles.map((option) => (
                  <Option key={option.id} value={option.id}>
                    {option?.make} {option?.model}
                  </Option>
                ))}
              </Dropdown>
            )}
          />
          <Label className={"side-drawer-field__label"}>
            Bay Number*
          </Label>
          <Controller
            name="vehicle.warehouseBayNumber"
            control={control}
            rules={{ required: false }}
            render={({ field }) => <input placeholder={"Enter Bay Number"} className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"} {...field} />}
          />
          <Label className={"side-drawer-field__label"}>
            Member
          </Label>
          <Controller
            name="memberId"
            control={control}
            rules={{ required: false }}
            render={({ field }) => <Dropdown multiselect={false}
              className={"side-drawer-field-dropdown  side-drawer-field__gap"}
              onOptionSelect={(ev, data) => {
                field.onChange(data.selectedOptions[0])
              }}
              selectedOptions={[field.value]}
              placeholder={!!selectedMemberPlaceholder ? selectedMemberPlaceholder : "Select Member"}
            >
              {props.users.map((option) => (
                <Option key={option.id} value={option.id}>
                  {`${option.firstName} ${option.lastName}`}
                </Option>
              ))}
            </Dropdown>}
          />
          <div className={"d-flex justify-space-between"} style={{ marginTop: 0 }}>
            <div style={{ display: "grid", width: "49%" }}>
              <Label className={"side-drawer-field__label"}>
                Date
              </Label>
              <Controller
                name="startDate"
                control={control}
                render={({ field }) => <div className={"datepicker-custom-wrap"}><DatePicker
                  showTimeSelect
                  selected={field.value}
                  contentAfter={<DateSVG />}
                  onSelectDate={field.onChange}
                  style={{
                    width: "100%",
                    height: "44px"
                  }}
                  placeholder="Select Start Date"
                  className={"side-drawer-field-default  side-drawer-field__gap side-drawer-field__gap"}

                  {...field}
                  dateFormat={'do MMMM yyyy hh:mm a'}
                /></div>}
              />
            </div>
            <div style={{ display: "grid", width: "49%" }}>
              <Label className={"side-drawer-field__label"} style={{}}>
                Time
              </Label>
              <Controller
                name="startTime"
                control={control}
                render={({ field }) => <input
                  className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"}
                  type="time" onChange={field.onChange}{...field} />}
              />
            </div>

          </div>
          <Label className={"side-drawer-field__label"}>
            Send Notifications
          </Label>
          <Controller
            name="isNotification"
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <div onClick={() => field.onChange(!field.value)}>
                <Switch checked={field.value} {...field} />
              </div>
            )}
          />
          <div style={{ position: "absolute", bottom: 0, width: "90%" }}>
            <div className="btnActions">
              <button
                className="form-submit-btn pointer"
                appearance="primary"
                type="submit"
              >
                {props.selectedVehicleReq ? "Update" : "Submit"}
              </button>
              <button
                className="form-submit-cancel pointer"
                onClick={() => props.setIsOpen(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </DrawerBody>
    </DrawerOverlay>
  );
}

export default VehiclesRequestForm;
