import {
    Button,
    Dropdown,
    Label,
    Option,
    Spinner,
    Textarea,
    makeStyles, shorthands
} from "@fluentui/react-components";
import {
    DrawerBody,
    DrawerHeader,
    DrawerHeaderTitle,
    DrawerOverlay,
} from "@fluentui/react-components/unstable";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import { DeleteFilled, DeleteRegular, Dismiss24Regular, bundleIcon } from "@fluentui/react-icons";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Controller, useForm } from "react-hook-form";
import { DateSVG, SwitchOffSVG, SwitchOnSVG } from "../../static/svgs";
import roomsDataService from "../../services/roomsDataService";
import { getTimeFromDate, getTimeFromDate2, mergeDateTime, mergeDateTime2 } from "../../Helper";
import { Timestamp } from "firebase/firestore";

const DeleteIcon = bundleIcon(DeleteFilled, DeleteRegular);

export const InitialRoomState = {
    "startDate": "",
    "endDate": "",
    "id": "",
    "roomType": "",
    "roomTypeId": "",
    "reasonClosure": "",
    "createdDate": new Date().toISOString(),
    "dateRangeOn": false,
    "type": "",
};

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        // Use 2px gap below the label (per the design system)
        ...shorthands.gap("2px")
    },
    control: {
        maxWidth: "300px",
    },
});

function SpaceClosuresForm(props) {
    const styles = useStyles();
    const { handleSubmit, control, reset, setValue, formState: { isDirty, dirtyFields } } = useForm({
        defaultValues: props.selectedRoom ? props.selectedRoom : InitialRoomState
    });
    const [dateRangeOn, setDateRangeOn] = useState(false);
    const [selectedRoomPlaceholder, setSelectedRoomPlaceholder] = useState('');
    const [isCloseDtOpen, setCloseDtOpen] = useState(false);
    const [isOpenStartDtRange, setOpenStartDtRange] = useState(false);
    const [isOpenEndDtRange, setOpenEndDtRange] = useState(false);
    const [showLoader, setShowLoader] = useState(false);

    const onSubmit = async formdata => {
        setShowLoader(true)
        let data = formdata;
        if (dateRangeOn) {
            // const mergedstartDateTime = mergeDateTime2(data.startDate, data.startTime);
            // const mergedendDateTime = mergeDateTime2(data.endDate, data.endTime);
            // data.startDate = moment(mergedstartDateTime).toISOString();
            // data.endDate = moment(mergedendDateTime).toISOString();
            const mergedstartDateTime = mergeDateTime(data.startDate, data.startTime);
            const mergedendDateTime = mergeDateTime(data.endDate, data.endTime);
            data.startDate = moment(mergedstartDateTime).toISOString();
            data.endDate = moment(mergedendDateTime).toISOString();

            const startTimestamp = Timestamp.fromMillis(Date.parse(mergedstartDateTime));
            const endTimestamp = Timestamp.fromMillis(Date.parse(mergedendDateTime));
            data.startTimestamp = startTimestamp;
            data.endTimestamp = endTimestamp;
            data.createdDate = new Date().toISOString();
            data.closingDate = moment.utc(data.endDate).toISOString();
        } else {
            data.startTime = "00:00"
            data.endTime = "00:00"
            data.startDate = moment.utc(data.closingDate).toISOString();
            data.endDate = moment.utc(data.closingDate).toISOString();
            data.createdDate = new Date().toISOString();
            data.closingDate = moment.utc(data.closingDate).toISOString();
        }

        data.dateRangeOn = dateRangeOn;
        data.type = "SpaceClosure";
        let id = "";
        let selectedRoomType = props.roomType.filter(obj => obj.id == data.roomTypeId);
        if (selectedRoomType && selectedRoomType.length > 0) {
            data.roomType = selectedRoomType[0].name;
        }
        if (props.selectedRoom) {
            id = props.selectedRoom.id;
            data.createdBy = props.createdBy;
            await roomsDataService.updateClosureHours(id, data);
        } else {
            data.createdBy = props.createdBy;
            const addeddata = await roomsDataService.addClosureHours(data);
            id = addeddata.id;
            data.id = id;
            let adata = await roomsDataService.updateClosureHours(id, data);
        }
        setShowLoader(false)
        props.setIsOpen(false);
        props.updateGrid();
    };

    useEffect(() => {
        const fields = ['id', 'roomType', 'roomTypeId', 'reasonClosure'];
        if (props.selectedRoom) {
            fields.forEach(field => setValue(field, props.selectedRoom[field]));
            setValue('startDate', moment(props.selectedRoom['startDate']).toDate());
            setValue('endDate', moment(props.selectedRoom['endDate']).toDate());
            const startTime = getTimeFromDate(props.selectedRoom.startDate);
            setValue('startTime', startTime);
            const endTime = getTimeFromDate(props.selectedRoom.endDate);
            setValue('endTime', endTime);

            setDateRangeOn(props.selectedRoom['dateRangeOn'])
            setSelectedRoomPlaceholder(`${props.selectedRoom['roomType']}`)
            if (props.selectedRoom['closingDate']) {
                setValue('closingDate', moment.utc(props.selectedRoom['closingDate']).toDate());
            }
        } else {
            fields.forEach(field => setValue(field, InitialRoomState[field]));
            setSelectedRoomPlaceholder('')
        }
    }, [props.selectedRoom])

    return (
        <DrawerOverlay
            size={"medium"}
            style={{ width: "504px" }}
            position={"right"}
            open={props.isOpen}
            onOpenChange={(_, { open }) => props.setIsOpen(open)}
        ><DrawerHeader style={{
            background: "#F7F7F7",
            padding: "26px 32px"
        }}>
                <DrawerHeaderTitle
                    action={
                        <Button
                            appearance="subtle"
                            aria-label="Close"
                            icon={<Dismiss24Regular />}
                            onClick={() => props.setIsOpen(false)}
                        />
                    }
                >
                    + {props.selectedRoom ? "Edit" : "New"} Space Closures
                </DrawerHeaderTitle>
            </DrawerHeader>
            <DrawerBody style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <form onSubmit={handleSubmit(onSubmit)}
                    className={styles.root}
                    onReset={reset}
                    id={props.selectedRoom ? props.selectedRoom.id : "New"}
                    style={{ flexGrow: 1, overflowY: 'auto' }}  // Allow form to grow and scroll if necessary
                >
                    <Label className={"side-drawer-field__label"} style={{ marginTop: "32px" }}>
                        Space Type*
                    </Label>
                    <Controller
                        name="roomTypeId"
                        control={control}
                        rules={{ required: true }}
                        placeholder={'Select Room Type'}
                        render={({ field }) =>
                            <Dropdown
                                multiselect={false}
                                placeholder={selectedRoomPlaceholder || 'Select Room Type'}
                                onOptionSelect={(ev, data) => field.onChange(data.selectedOptions[0])}
                                className={"side-drawer-field-dropdown  side-drawer-field__gap"}
                                selectedOptions={[field.value]}
                            >
                                {props.roomType.map((option) => (
                                    <Option key={option.id} value={option.id}>
                                        {option.name}
                                    </Option>
                                ))}
                            </Dropdown>
                        }
                    />
                    <Label className={"side-drawer-field__label"}>Date Range</Label>
                    <div onClick={() => { setDateRangeOn(!dateRangeOn) }}>
                        {dateRangeOn ? <SwitchOnSVG /> : <SwitchOffSVG />}
                    </div>
                    {dateRangeOn ? (
                        <div>
                            <div className={"d-flex justify-space-between"}>
                                <div style={{ display: "grid", width: "49%" }}>
                                    <Label className={"side-drawer-field__label"} style={{ marginTop: "24px" }}>
                                        Start Closing Date*
                                    </Label>
                                    <Controller
                                        name="startDate"
                                        control={control}
                                        render={({ field }) =>
                                            <div className={"datepicker-custom-wrap"} onClick={() => setOpenStartDtRange(true)}>
                                                <DatePicker
                                                    showTimeSelect
                                                    selected={field.value}
                                                    open={isOpenStartDtRange}
                                                    onOpenChange={(open) => setOpenStartDtRange(open)} // Sync open state
                                                    contentAfter={<DateSVG />}
                                                    onSelectDate={field.onChange}
                                                    style={{ width: "100%", height: "44px" }}
                                                    placeholder="Select Start Date"
                                                    className={"side-drawer-field-default side-drawer-field__gap"}
                                                    {...field}
                                                    dateFormat={'do MMMM yyyy hh:mm a'}
                                                />
                                            </div>
                                        }
                                    />
                                </div>
                                <div style={{ display: "grid", width: "49%" }}>
                                    <Label className={"side-drawer-field__label"} style={{ marginTop: "24px" }}>
                                        Start Closing Time*
                                    </Label>
                                    <Controller
                                        name="startTime"
                                        control={control}
                                        render={({ field }) =>
                                            <input
                                                className={"side-drawer-field side-drawer-field__gap"}
                                                type="time"
                                                onChange={field.onChange}
                                                {...field}
                                            />
                                        }
                                    />
                                </div>
                            </div>
                            <div className={"d-flex justify-space-between"}>
                                <div style={{ display: "grid", width: "49%" }}>
                                    <Label className={"side-drawer-field__label"}>End Closing Date*</Label>
                                    <Controller
                                        name="endDate"
                                        control={control}
                                        render={({ field }) =>
                                            <div className={"datepicker-custom-wrap"} onClick={() => setOpenEndDtRange(true)}>
                                                <DatePicker
                                                    showTimeSelect
                                                    selected={field.value}
                                                    open={isOpenEndDtRange}
                                                    onOpenChange={(open) => setOpenEndDtRange(open)} // Sync open state
                                                    contentAfter={<DateSVG />}
                                                    onSelectDate={field.onChange}
                                                    style={{ width: "100%", height: "44px" }}
                                                    placeholder="Select End Date"
                                                    className={"side-drawer-field-default side-drawer-field__gap"}
                                                    {...field}
                                                    dateFormat={'do MMMM yyyy hh:mm a'}
                                                />
                                            </div>
                                        }
                                    />
                                </div>
                                <div style={{ display: "grid", width: "49%" }}>
                                    <Label className={"side-drawer-field__label"}>End Closing Time*</Label>
                                    <Controller
                                        name="endTime"
                                        control={control}
                                        render={({ field }) =>
                                            <input
                                                className={"side-drawer-field side-drawer-field__gap"}
                                                type="time"
                                                onChange={field.onChange}
                                                {...field}
                                            />
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div className={"d-flex justify-space-between"}>
                                <div style={{ display: "grid", width: "49%" }}>
                                    <Label className={"side-drawer-field__label"} style={{ marginTop: "24px" }}>
                                        Closing Date*
                                    </Label>
                                    <Controller
                                        name="closingDate"
                                        control={control}
                                        render={({ field }) =>
                                            <div className={"datepicker-custom-wrap"} onClick={() => setCloseDtOpen(true)}>
                                                <DatePicker
                                                    showTimeSelect
                                                    selected={field.value}
                                                    onSelectDate={field.onChange}
                                                    contentAfter={<DateSVG />}
                                                    open={isCloseDtOpen}
                                                    onOpenChange={(open) => setCloseDtOpen(open)} // Sync open state
                                                    style={{ width: "100%", height: "44px" }}
                                                    placeholder="Select Start Date"
                                                    className="side-drawer-field-default side-drawer-field__gap"
                                                    dateFormat={'do MMMM yyyy hh:mm a'}
                                                    {...field}
                                                />
                                            </div>
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                    <Label className={"side-drawer-field__label"}>Reason for Closure</Label>
                    <Controller
                        name="reasonClosure"
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) =>
                            <Textarea
                                {...field}
                                resize="vertical"
                                placeholder={'Reason for Closure'}
                                className={"side-drawer-field-Textarea side-drawer-field__gap"}
                                style={{ borderRadius: "16px !important" }}
                            />
                        }
                    />

                    {/* Button Section: This will be pushed to the bottom */}
                    <div className="btnActions" style={{ marginTop: 'auto' }}>
                        {showLoader ? (
                            <Spinner label="Loading..." />
                        ) : (
                            <>
                                <button appearance="primary" type="submit" className="form-submit-btn pointer">
                                    {props.selectedRoom ? 'Update' : 'Submit'}
                                </button>
                                <button className="form-submit-cancel pointer" onClick={() => props.setIsOpen(false)}>
                                    Cancel
                                </button>
                            </>
                        )}
                    </div>
                </form>
            </DrawerBody>
        </DrawerOverlay>
    )
}

export default SpaceClosuresForm