import {
    Button,
    Label,
    Table,
    TableBody,
    TableCell,
    TableCellLayout,
    TableHeader,
    TableHeaderCell,
    TableRow,
    makeStyles,
    shorthands
} from "@fluentui/react-components";
import { Tag, TagGroup } from "@fluentui/react-tags";
import axios from "axios";
import { collection, getDocs, query, where } from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { useAuthState } from 'react-firebase-hooks/auth';
import { useLocation, useNavigate } from "react-router-dom";
import Layout from "./components/Layout";
import { Base_Url, auth, db } from "./services/firebase";
import { CalanderSVG, DownArowSVG } from "./static/svgs";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import moment from 'moment';
import { BookingCategory } from "./Helper";

// Update columns to match the charges API response
const columns = [
    { columnKey: "chargeId", label: "Charge ID", width: '20%' },
    { columnKey: "amount", label: "Amount (USD)", width: '10%' },
    { columnKey: "created", label: "Date Created", width: '10%' },
    { columnKey: "description", label: "Description", width: '10%' },
    { columnKey: "isPaymentFrom", label: "Payment From", width: '10%' } // New field added
];

function UserChargeDetails() {
    const styles = useStyles();
    const [allCharges, setAllCharges] = useState([]);
    const [filteredCharges, setFilteredCharges] = useState([]); // New state for filtered charges
    const [stripeCustomerId, setStripeCustomerId] = useState(null); // State to hold Stripe Customer ID
    const [totalAmount, setTotalAmount] = useState(0); // State for total amount
    const navigate = useNavigate();
    const { state } = useLocation();
    const [user, loading, error] = useAuthState(auth);
    const [visibleTags, setVisibleTags] = useState([]);
    const firstTagRef = useRef(null);
    const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
    const [popupVisible, setPopupVisible] = useState(false);
    const [filterOptions, setFilterOptions] = useState([
        { id: 1, name: 'Paid_Event_RSVP', selected: false },
        { id: 2, name: 'Addition_Amount', selected: false },
        { id: 3, name: 'Vehicle_Booking', selected: false },
        { id: 4, name: 'Purchase_Product', selected: false },
        { id: 5, name: 'Purchase_Product_Gratuity_Tax', selected: false },
        { id: 6, name: 'Addon', selected: false },
        { id: 7, name: 'Minimum_Spend_Adjustment', selected: false },
        { id: 8, name: 'Other', selected: false }
    ]);
    const [popupVisibleDate, setPopupVisibleDate] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedStartDate, setSelectedStartDate] = useState(null);
    const [selectedEndDate, setSelectedEndDate] = useState(null);

    useEffect(() => {
        // Set the start date to one month ago
        const startDate = moment().subtract(1, 'months'); // One month ago
        const endDate = moment(); // Today

        // Set the selected dates to state
        setSelectedStartDate(startDate);
        setSelectedEndDate(endDate);
    }, []);

    useEffect(() => {
        if (state && state?.email) {
            console.log("--->", state?.email);
            fetchUserNameAndVehicles();
        } else {
            navigate("/");
        }
    }, [state, navigate]);

    // Fetch charges whenever the stripeCustomerId or date range changes
    useEffect(() => {
        // Set the start date to one month ago
        const startDate = moment().subtract(1, 'months'); // One month ago
        const endDate = moment(); // Today

        // Set the selected dates to state
        setSelectedStartDate(startDate);
        setSelectedEndDate(endDate); // End date is today

        if (stripeCustomerId) {
            fetchCharges(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')); // Pass formatted dates to fetchCharges
        }
    }, [stripeCustomerId]);


    const fetchUserNameAndVehicles = async () => {
        try {
            const userquery = query(collection(db, "Users"), where("email", "==", state?.email));
            const userdoc = await getDocs(userquery);
            if (userdoc.docs.length > 0) {
                const userData = userdoc.docs[0].data(); // Get the first document
                setStripeCustomerId(userData.stripeCustomerId); // Set the customer ID from user data
            } else {
                console.log('No access');
            }
        } catch (err) {
            console.error(err);
            alert("An error occurred while fetching user data");
        }
    };

    const fetchCharges = (startDate, endDate) => {
        if (!stripeCustomerId) {
            console.log('No Stripe customer ID found.');
            return;
        }
        const config = {
            method: 'get',
            url: `${Base_Url}/getChargesByCustomer`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': `Bearer ${user.accessToken}`,
                'Accept': "application/json"
            },
            params: {
                stripeCustomerId: stripeCustomerId,
                startDate: startDate,
                endDate: endDate
            }
        };

        axios.request(config)
            .then((response) => {
                if (response.status === 200) {
                    const charges = response.data.charges.map(charge => ({
                        chargeId: charge.id,
                        amount: (charge.amount / 100).toFixed(2),
                        created: new Date(charge.created * 1000).toLocaleDateString(),
                        status: charge.status,
                        description: charge.description || "No description",
                        isPaymentFrom: charge.metadata && charge.metadata.isPaymentFrom ? charge.metadata.isPaymentFrom : "No metadata",
                        metadata: charge.metadata ? charge.metadata : null
                    }));

                    setAllCharges(charges);
                    setFilteredCharges(charges); // Set filtered charges to the same initially
                    calculateTotal(charges);
                } else {
                    console.error(response.data.message || 'Failed to fetch charges.');
                }
            })
            .catch((err) => {
                console.error(err);
                alert("An error occurred while fetching charge data.");
            });
    }

    // Function to calculate the total amount from all charges
    const calculateTotal = (charges) => {
        const total = charges.reduce((acc, charge) => acc + parseFloat(charge.amount), 0);
        setTotalAmount(total.toFixed(2)); // Set the total amount to state
    };

    const openPopup = (event) => {
        // Calculate the position of the button
        const buttonRect = event.target.getBoundingClientRect();
        const buttonX = buttonRect.left + (buttonRect.width / 2);
        const buttonY = buttonRect.top + (buttonRect.height / 2);

        // Set the position of the popup relative to the button
        setPopupPosition({ x: buttonX, y: buttonY + 10 }); // Adjust 10px downward for better alignment

        // Show the popup
        setPopupVisible(true);
    };
    const removeItem = (e, { value }) => {
        const updatedTags = visibleTags.filter((tag) => tag.value !== value);
        setVisibleTags(updatedTags);

        // Update filter options and set selected state to false
        const updatedFilterOptions = filterOptions.map(option =>
            option.id === value ? { ...option, selected: false } : option
        );
        setFilterOptions(updatedFilterOptions);

        // Update filtered charges and total amount
        handleApplyFilter(updatedFilterOptions);
    };

    const toggleSelect = (id) => {
        setFilterOptions((prevOptions) =>
            prevOptions.map((option) =>
                option.id === id ? { ...option, selected: !option.selected } : option
            )
        );
    };

    const handleApplyFilter = (updatedFilterOptions = filterOptions) => {
        // Ensure that updatedFilterOptions is an array
        if (!Array.isArray(updatedFilterOptions)) {
            console.error('Expected updatedFilterOptions to be an array', updatedFilterOptions);
            return; // Exit the function if not an array
        }

        const selectedOptions = updatedFilterOptions.filter(option => option.selected);
        const selectedValues = selectedOptions.map(option => option.name);

        // Define the descriptions you want to exclude when "Other" is selected
        const descriptionsToExclude = [
            'Paid_Event_RSVP',
            'Addition_Amount',
            'Vehicle_Booking',
            'Purchase_Product',
            'Purchase_Product_Gratuity_Tax',
            'Addon',
            'Minimum_Spend_Adjustment'
        ];

        // Filter charges based on the selected options
        const newFilteredCharges = allCharges.filter(charge => {
            // If "Other" is selected, exclude specific descriptions
            if (selectedValues.includes("Other")) {
                return !descriptionsToExclude.includes(charge.isPaymentFrom);
            }
            // Otherwise, check if the charge matches the selected values
            return selectedValues.length === 0 || selectedValues.includes(charge.isPaymentFrom);
        });

        setFilteredCharges(newFilteredCharges); // Update the filtered charges state
        calculateTotal(newFilteredCharges); // Calculate total for filtered charges
        const updatedTags = selectedOptions.map(option => ({
            value: option.id,
            children: option.name
        }));
        setVisibleTags(updatedTags);
        setPopupVisible(false);
    };

    const handleCancelFilter = () => {
        // Close the popup
        setPopupVisible(false);
    };

    const openDatePopup = (event) => {
        // Calculate the position of the button
        const buttonRect = event.target.getBoundingClientRect();
        const buttonX = buttonRect.left + (buttonRect.width / 2);
        const buttonY = buttonRect.top + (buttonRect.height / 2);
        // Set the position of the popup relative to the button
        setPopupPosition({ x: buttonX, y: buttonY + 10 }); // Adjust 10px downward for better alignment
        // Show the popup
        setPopupVisible(false);
        setPopupVisibleDate(true);
    };

    const handleDateApply = () => {
        // Ensure that both dates are selected before fetching charges
        if (selectedStartDate && selectedEndDate) {
            // Set the selected option to "CustomDate"
            setSelectedOption("CustomDate");
            // Convert Moment.js objects to the desired format (YYYY-MM-DD) for the API
            const formattedStartDate = selectedStartDate.format('YYYY-MM-DD'); // Use Moment's format
            const formattedEndDate = selectedEndDate.format('YYYY-MM-DD'); // Use Moment's format

            // Fetch charges with the selected date range
            fetchCharges(formattedStartDate, formattedEndDate);

            // Close the popup
            setPopupVisible(false);
            setPopupVisibleDate(false);
        } else {
            // Alert if either of the dates are not selected
            alert("Please select both start and end dates.");
        }
    };

    const handleDateSelect = (date) => {
        setSelectedOption("CustomDate")
        setSelectedStartDate(moment(date)); // Ensure it's a Moment object
    };
    const handleDateSelect2 = (date) => {
        setSelectedOption("CustomDate")
        setSelectedEndDate(moment(date)); // Ensure it's a Moment object
    };

    const handleCancel = () => {
        setSelectedOption(null)
        setPopupVisible(false);
        setPopupVisibleDate(false);
    };

    return (
        <Layout>
            <div className={styles.content}>
                <div className={styles.breakFlex}>
                    <Button shape="circular" appearance="primary" onClick={() => navigate(-1)}>Back</Button>
                </div>
                {state && state.firstName && state.lastName && (
                    <div className={styles.userDetails}>
                        <p><strong>Name:</strong> {state.firstName} {state.lastName}</p>
                    </div>
                )}
                <div className={"d-flex"}>
                    <div className={'memberNameDiv'} onClick={openPopup}>
                        <span>Payment From</span>
                        <DownArowSVG />
                    </div>
                    <div className={'memberNameDiv'} onClick={openDatePopup}>
                        <span>Order Date</span>
                        <DownArowSVG />
                    </div>
                </div>
                <div className={'dateDisplay'}>
                    <p>
                        <strong>Start Date:</strong> {selectedStartDate ? selectedStartDate.format('YYYY-MM-DD') : 'Loading...'}
                    </p>
                    <p>
                        <strong>End Date:</strong> {selectedEndDate ? selectedEndDate.format('YYYY-MM-DD') : 'Loading...'}
                    </p>
                </div>
                <div style={{ marginTop: '10px' }}>
                    {visibleTags.length !== 0 && (
                        <TagGroup onDismiss={removeItem} aria-label="Dismiss example">
                            {visibleTags.map((tag, index) => (
                                <Tag
                                    dismissible
                                    dismissIcon={{ "aria-label": "remove" }}
                                    value={tag.value}
                                    key={tag.value}
                                    ref={index === 0 ? firstTagRef : null}
                                >
                                    {tag.children}
                                </Tag>
                            ))}
                        </TagGroup>
                    )}
                </div>
                {/* Display Total Amount */}
                <div className={styles.totalAmount}>
                    <p>Total: ${totalAmount}</p>
                </div>

                <div>
                    <Table aria-label="Table with charge details">
                        <TableHeader className={"list-row"}>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableHeaderCell style={{ width: column.width }} key={column.columnKey}>
                                        <span className={"listHeaderText"}>{column.label}</span>
                                    </TableHeaderCell>
                                ))}
                            </TableRow>
                        </TableHeader>
                        {filteredCharges.length > 0 &&
                            <TableBody>
                                {filteredCharges.map((charge) =>
                                    <TableRow key={charge.chargeId}>
                                        <TableCell>
                                            <TableCellLayout>
                                                {charge.chargeId}
                                            </TableCellLayout>
                                        </TableCell>
                                        <TableCell>
                                            <TableCellLayout>
                                                <Label title={charge.amount}>${charge.amount}</Label>
                                            </TableCellLayout>
                                        </TableCell>
                                        <TableCell>
                                            <TableCellLayout>
                                                <Label title={charge.created}>{charge.created}</Label>
                                            </TableCellLayout>
                                        </TableCell>
                                        <TableCell>
                                            <TableCellLayout>
                                                {
                                                    charge.metadata ?
                                                        charge.metadata.productInfo ?
                                                            <Label title={charge.description}>{charge.description}</Label>
                                                            // <ProductList productInfo={charge.metadata.productInfo} />
                                                            :
                                                            charge.metadata && charge.metadata.isPaymentFrom && charge.metadata.isPaymentFrom == BookingCategory.Paid_Event_RSVP ?
                                                                <EventInfo eventInfo={charge.metadata.eventInfo} />
                                                                :
                                                                charge.metadata && charge.metadata.isPaymentFrom && charge.metadata.isPaymentFrom == BookingCategory.Vehicle_Booking ?
                                                                    <VehicleInfo eventInfo={charge.metadata} />
                                                                    :
                                                                    <Label title={charge.description}>{charge.description}</Label>
                                                        : <div />
                                                }
                                            </TableCellLayout>
                                        </TableCell>
                                        <TableCell>
                                            <TableCellLayout>
                                                <Label title={charge.isPaymentFrom}>{charge.isPaymentFrom}</Label> {/* Render new field */}
                                            </TableCellLayout>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        }
                    </Table>
                </div>
                {popupVisible && (
                    <div className={styles.popupmain} style={{ position: 'absolute', top: popupPosition.y, left: popupPosition.x }}>
                        <div className={styles.popupMemberList}>
                            {filterOptions
                                .map((option) => (
                                    <div key={option.id}>
                                        <Label className="checkLabeldiv">
                                            <input
                                                type="checkbox"
                                                checked={option.selected}
                                                onChange={() => toggleSelect(option.id)}
                                            />
                                            {option.name}
                                        </Label>
                                    </div>
                                ))}
                        </div>
                        <div className="buttonContainer">
                            <button className="borderButton" onClick={handleCancelFilter}>Cancel</button>
                            <button className="fillButton" onClick={() => { handleApplyFilter() }}>APPLY</button>
                        </div>
                    </div>
                )}
                {popupVisibleDate && (
                    <div className="popupmain" style={{ position: 'absolute', top: popupPosition.y, left: popupPosition.x }}>
                        <div className="dateFramesContainer">
                            <div style={{ width: '48%' }}>
                                <span>Start Date</span>
                                <DatePicker
                                    showTimeSelect
                                    selected={selectedStartDate ? selectedStartDate.toDate() : null} // Convert Moment to JS Date
                                    contentAfter={<CalanderSVG />}
                                    onSelectDate={handleDateSelect}
                                    style={{
                                        width: "100%",
                                        height: "44px"
                                    }}
                                    placeholder="Start Date"
                                />
                            </div>
                            <div className="dashLine"></div> {/* Add a separate element for the dash line */}
                            <div style={{ width: '48%' }}>
                                <span>End Date</span>
                                <DatePicker
                                    showTimeSelect
                                    selected={selectedEndDate ? selectedEndDate.toDate() : null} // Convert Moment to JS Date
                                    contentAfter={<CalanderSVG />}
                                    onSelectDate={handleDateSelect2}
                                    style={{
                                        width: "100%",
                                        height: "44px"
                                    }}
                                    placeholder="End Date"
                                />
                            </div>
                        </div>

                        <div className="seperateLine"></div>
                        <div className="buttonContainer">
                            <button className="borderButton" onClick={handleCancel}>Cancel</button>
                            <button className="fillButton" onClick={handleDateApply}>APPLY</button>
                        </div>
                    </div>
                )}
            </div>
        </Layout>
    );
}

export default UserChargeDetails;
const useStyles = makeStyles({
    root: {
        ...shorthands.overflow("hidden"),
        display: "flex",
        height: "480px",
        backgroundColor: "#fff"
    },

    content: {
        ...shorthands.flex(1),
        ...shorthands.padding("16px"),
        display: "block",
        ...shorthands.gridArea("main")
    },

    breakFlex: {
        flexBasis: "100%",
        textAlign: "left"
    },
    popupmain: {
        width: '256px',
        height: '280px',
        position: 'relative',
        top: '124px',
        borderRadius: '10px',
        border: '1px solid #F4F4F4',
        background: '#FFF',
        boxShadow: '1px 1px 4px 0px rgba(89, 89, 89, 0.25)',
        padding: '16px',
        overflowY: 'auto',
    },
    popupMemberList: {
        paddingTop: '10px',
        maxHeight: '266px',
        overflowY: 'auto'
    },
    totalAmount: {
        marginTop: "16px",
        fontWeight: "bold"
    }
});
const ProductList = ({ productInfo }) => {

    const calculateTotal = (items) => {
        return items.reduce((total, product) => {
            return total + (product.price * product.quantity);
        }, 0);
    };

    const totalAmount = calculateTotal(productInfo);
    return (
        <div>
            {productInfo.map((product, index) => (
                <div key={index}>
                    <div key={index} style={styles2.productDetailsContainer}>
                        <div className="d-flex" style={{}}>
                            <div style={styles2.productName}>{product.quantity} x</div>
                            <div style={styles2.productName2} >{`${product.name}`}/</div>
                            <div style={styles2.productName}>${(product.price / 100).toFixed(2)}</div>
                        </div>
                    </div>
                </div>
            ))}
            <div style={styles2.productTotal}>Total: ${(totalAmount / 100).toFixed(2)}</div>
        </div>
    );
};

const EventInfo = ({ eventInfo }) => {
    if (eventInfo) {
        let eventInfoArr = JSON.parse(eventInfo);
        if (eventInfoArr && eventInfoArr.length > 0) {
            return (
                eventInfoArr.map((item, index) => (
                    <div key={index}>
                        <div key={index} style={styles2.productDetailsContainer}>
                            <div className="d-flex" style={{}}>
                                <div style={styles2.productName2} >{`${item.name}`}</div>
                            </div>
                        </div>
                    </div>
                ))
            )
        } else {
        }
    }
    return (<div />)
};

const VehicleInfo = ({ eventInfo }) => {
    if (eventInfo && eventInfo.otherInfo) {
        let otherInfo = JSON.parse(eventInfo.otherInfo)
        return (
            <div>
                <div style={styles2.productDetailsContainer}>
                    <div className="d-flex" style={{}}>
                        <div style={styles2.productName}>{otherInfo.id}</div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div>
            </div>
        );
    }
};

const styles2 = {
    productDetailsContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    imageContainer: {
        marginRight: 10,
    },
    productImage: {
        width: 62,
        height: 62,
        borderRadius: 5,
    },
    productName: {
        color: '#202020',
        fontFamily: 'Nunito Sans',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px'
    },
    productName2: {
        marginLeft: '6px',
        color: '#202020',
        fontFamily: 'Nunito Sans',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px'
    },
    productTotal: {
        marginTop: '2px',
        color: '#202020',
        fontFamily: 'Nunito Sans',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '20px',
    },
    productPrice: {
        fontSize: 12,
        color: '#535353'
    },
    separator: {
        width: '100%',
        borderTop: '1px solid #ccc',
        marginTop: 10,
        marginBottom: 10
    }
};
