import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, updateDoc, startAfter, limit, query, orderBy, where } from "firebase/firestore";
import { db } from "./firebase";
import { Timestamp } from "firebase/firestore";
const { startOfMonth, endOfMonth, subMonths } = require('date-fns');

const orderHistoryCollection = collection(db, "OrderHistory");
const transactionHistoryCollection = collection(db, "TransactionHistory");

class OrderHistoryDataService {
    addOrderHistory = (newOrderHistory) => {
        return addDoc(orderHistoryCollection, newOrderHistory);
    }

    updateOrderHistory = (id, updatedOrderHistory) => {
        const orderHistoryDoc = doc(db, "OrderHistory", id);
        return updateDoc(orderHistoryDoc, updatedOrderHistory);
    }

    deleteOrderHistory = (id) => {
        const orderHistoryDoc = doc(db, "OrderHistory", id);
        return deleteDoc(orderHistoryDoc);
    }

    getAllOrderHistory = () => {
        return getDocs(orderHistoryCollection)
    }

    getAllOrderHistoryWithPage = async (pageSize, startAfterdt) => {
        let q = query(orderHistoryCollection, orderBy("createdDate", 'desc')); // Initialize query
        if (startAfterdt) {
            q = query(q, startAfter(startAfterdt)); // Corrected: Use query to chain methods
        }
        if (pageSize) {
            q = query(q, limit(pageSize)); // Corrected: Use query to chain methods
        }
        const snapshot = await getDocs(q);
        const orderHistory = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        const lastVisible = snapshot.docs[snapshot.docs.length - 1];
        return { orderHistory, lastVisible };
    };
    getOrderHistoryByUserIdWithPage = async (emailIds, pageSize, startAfterdt) => {
        // Initialize query with user IDs filter and order by createdAt
        let q = query(
            orderHistoryCollection,
            where("email", "in", emailIds),
            orderBy("createdAt", 'desc')
        );
        if (startAfterdt) {
            q = query(q, startAfter(startAfterdt));
        }
        if (pageSize) {
            q = query(q, limit(pageSize));
        }
        const snapshot = await getDocs(q);
        const orderHistory = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        const lastVisible = snapshot.docs[snapshot.docs.length - 1];
        return { orderHistory, lastVisible };
    };

    getAllOrderHistoryBySelection = async (selection, startDt, endDt, emailIds = []) => {
        let startDate, endDate;
        // Get the current timestamp
        const currentTimestamp = Timestamp.now();
        // Calculate start and end timestamps based on selection
        switch (selection) {
            case "CustomDate":
                startDate = Timestamp.fromDate(new Date(startDt));
                endDate = Timestamp.fromDate(new Date(endDt));
                break;
            case "Today":
                const todayStart = new Date();
                todayStart.setHours(0, 0, 0, 0); // Start of today
                const todayEnd = new Date();
                todayEnd.setHours(23, 59, 59, 999); // End of today
                startDate = Timestamp.fromDate(todayStart);
                endDate = Timestamp.fromDate(todayEnd);
                break;
            case "Yesterday":
                const yesterdayStart = new Date(currentTimestamp.toDate());
                yesterdayStart.setDate(yesterdayStart.getDate() - 1);
                yesterdayStart.setHours(0, 0, 0, 0); // Start of yesterday
                const yesterdayEnd = new Date(yesterdayStart);
                yesterdayEnd.setHours(23, 59, 59, 999); // End of yesterday
                startDate = Timestamp.fromDate(yesterdayStart);
                endDate = Timestamp.fromDate(yesterdayEnd);
                break;
            case "This month":
                const startOfMonthTimestamp = Timestamp.fromMillis(startOfMonth(new Date()).getTime());
                const endOfMonthTimestamp = Timestamp.fromMillis(endOfMonth(new Date()).getTime() + 999);
                startDate = startOfMonthTimestamp;
                endDate = endOfMonthTimestamp;
                break;
                break;
            case "Past month":
                // Calculate start and end timestamps for the past month
                const startOfPastMonth = subMonths(startOfMonth(new Date()), 1);
                const endOfPastMonth = endOfMonth(subMonths(new Date(), 1));
                startDate = Timestamp.fromMillis(startOfPastMonth.getTime());
                endDate = Timestamp.fromMillis(endOfPastMonth.getTime() + 999);
                break;
            default:
                startDate = null;
                endDate = null;
                break;
        }
        // Construct the query
        let q = query(orderHistoryCollection, orderBy("createdAt", 'desc'));
        if (startDate && endDate) {
            q = query(q, where("createdAt", ">=", startDate), where("createdAt", "<=", endDate));
        }
        // Add the userIds filter if the array is provided and not empty
        if (emailIds.length > 0) {
            q = query(q, where("email", "in", emailIds));
        }
        // Execute the query
        const snapshot = await getDocs(q);
        const orderHistory = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        const lastVisible = snapshot.docs[snapshot.docs.length - 1];
        return { orderHistory, lastVisible };
    };

    getOrderHistory = (id) => {
        const orderHistoryDoc = doc(db, "OrderHistory", id);
        return getDoc(orderHistoryDoc);
    }

    getAllTransactionHistoryWithPage = async (pageSize, startAfterdt) => {
        let q = query(transactionHistoryCollection, orderBy("createdAt", 'desc')); // Initialize query
        if (startAfterdt) {
            q = query(q, startAfter(startAfterdt)); // Corrected: Use query to chain methods
        }
        if (pageSize) {
            q = query(q, limit(pageSize)); // Corrected: Use query to chain methods
        }
        const snapshot = await getDocs(q);
        const orderHistory = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        const lastVisible = snapshot.docs[snapshot.docs.length - 1];
        return { orderHistory, lastVisible };
    };

    getTransactionHistoryByUserIdWithPage = async (userIds, pageSize, startAfterdt) => {
        // Initialize query with user IDs filter and order by createdAt
        let q = query(
            transactionHistoryCollection,
            where("userId", "in", userIds),
            orderBy("createdAt", 'desc')
        );
        if (startAfterdt) {
            q = query(q, startAfter(startAfterdt));
        }
        if (pageSize) {
            q = query(q, limit(pageSize));
        }
        const snapshot = await getDocs(q);
        const orderHistory = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        const lastVisible = snapshot.docs[snapshot.docs.length - 1];
        return { orderHistory, lastVisible };
    };

    getTransactionHistoryBySelection = async (selection, startDt, endDt, userIds = []) => {
        let startDate, endDate;
        const currentTimestamp = Timestamp.now();

        switch (selection) {
            case "CustomDate":
                startDate = Timestamp.fromDate(new Date(startDt));
                endDate = Timestamp.fromDate(new Date(endDt));
                break;
            case "Today":
                const todayStart = new Date();
                todayStart.setHours(0, 0, 0, 0); // Start of today
                const todayEnd = new Date();
                todayEnd.setHours(23, 59, 59, 999); // End of today
                startDate = Timestamp.fromDate(todayStart);
                endDate = Timestamp.fromDate(todayEnd);
                break;
            case "Yesterday":
                const yesterdayStart = new Date(currentTimestamp.toDate());
                yesterdayStart.setDate(yesterdayStart.getDate() - 1);
                yesterdayStart.setHours(0, 0, 0, 0); // Start of yesterday
                const yesterdayEnd = new Date(yesterdayStart);
                yesterdayEnd.setHours(23, 59, 59, 999); // End of yesterday
                startDate = Timestamp.fromDate(yesterdayStart);
                endDate = Timestamp.fromDate(yesterdayEnd);
                break;
            case "This month":
                const startOfMonthTimestamp = Timestamp.fromMillis(startOfMonth(new Date()).getTime());
                const endOfMonthTimestamp = Timestamp.fromMillis(endOfMonth(new Date()).getTime() + 999);
                startDate = startOfMonthTimestamp;
                endDate = endOfMonthTimestamp;
                break;
            case "Past month":
                const startOfPastMonth = subMonths(startOfMonth(new Date()), 1);
                const endOfPastMonth = endOfMonth(subMonths(new Date(), 1));
                startDate = Timestamp.fromMillis(startOfPastMonth.getTime());
                endDate = Timestamp.fromMillis(endOfPastMonth.getTime() + 999);
                break;
            default:
                startDate = null;
                endDate = null;
                break;
        }

        // Construct the query
        let q = query(transactionHistoryCollection, orderBy("createdAt", 'desc'));

        // Add the date range filter if startDate and endDate are defined
        if (startDate && endDate) {
            q = query(q, where("createdAt", ">=", startDate), where("createdAt", "<=", endDate));
        }

        // Add the userIds filter if the array is provided and not empty
        if (userIds.length > 0) {
            q = query(q, where("userId", "in", userIds));
        }

        // Execute the query
        const snapshot = await getDocs(q);
        const orderHistory = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        const lastVisible = snapshot.docs[snapshot.docs.length - 1];

        return { orderHistory, lastVisible };
    };

    addTransactionHistory = (newTransactionHistory) => {
        return addDoc(transactionHistoryCollection, newTransactionHistory);
    }
    updateTransactionHistory = (id, newTransactionHistory) => {
        const orderHistoryDoc = doc(db, "TransactionHistory", id);
        return updateDoc(orderHistoryDoc, newTransactionHistory);
    }

}

export default new OrderHistoryDataService();